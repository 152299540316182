import React, {useEffect, useState} from 'react'
import Footer from './Footer'
import Navigator from './Navigator'
import Top from './Top'
import Programs, { ProgramSkeleton } from './Programs'
import News, {NewsSkeleton} from './News'
import Banner from './Banner'
import MissionVision from './MissionVision'
import ContactForm from './ContactForm'
import Whatsapp from './Whatsapp'
import useSmoothScrollToTop from './useSmoothScroll'
import useFetch from './useFetch'
import { domain } from './Domain'
import { motion, AnimatePresence} from "framer-motion"
import Loader from './Loader'
import OnlineStream from './OnlineStream'
import NewsBanner from './NewsBanner'
import { Helmet } from 'react-helmet'
import { NewsbannerSkeleton } from './NewsBanner'

//<Banner />
function Entry() {
    const {data:news, loading:loadingNews, error:errNews, refresh:refNews, setRefresh:setRefNews} = useFetch(`${domain}/news`);
    const items = Array(3).fill(null);
    const items2 = Array(4).fill(null);

    const [ spinner, setSpinner ] = useState(true);
    useEffect(()=>{
        setTimeout(() => setSpinner(false), 500);
    },[])

    useSmoothScrollToTop();
    return (
        spinner ? <Loader /> : 
            <div>
                <Helmet>
                    <title>Radio Divine FM</title>
                    <meta name="description" content="Radio Divine 90.6 Fm signal has a radium of about 250 Km. with about 7.2 million people (listeners), With excellent coverage in Apac, Lira, Oyam, Kole, Gulu, Pader, Nwoya, Agago, Otuke, Alebtong, Dokolo, Amolatar, Kiryandongo, Nakasongola, Masindi Districts. We also reach out with very good signal, Nebbi, Amuru, Adjumani, Lamwo, Kitgum, Kotido, Abim, Napak, Amuru, Amuru, Katakwi, Soroti, Ngora, Serere," />
                    <meta property="og:title" content="Radio Divine FM" />
                    <meta property="og:description" content="Radio Divine 90.6 Fm signal has a radium of about 250 Km. with about 7.2 million people (listeners), With excellent coverage in Apac, Lira, Oyam, Kole, Gulu, Pader, Nwoya, Agago, Otuke, Alebtong, Dokolo, Amolatar, Kiryandongo, Nakasongola, Masindi Districts. We also reach out with very good signal, Nebbi, Amuru, Adjumani, Lamwo, Kitgum, Kotido, Abim, Napak, Amuru, Amuru, Katakwi, Soroti, Ngora, Serere," />
                    <meta property="og:image" content="https://radiodivine.co.ug/images/divine_logo.png" />
                    <meta property="og:url" content="https://radiodivine.co.ug/images/divine_logo.png" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="Radio Divine FM" />
                    <meta name="twitter:description" content="Radio Divine 90.6 Fm signal has a radium of about 250 Km. with about 7.2 million people (listeners), With excellent coverage in Apac, Lira, Oyam, Kole, Gulu, Pader, Nwoya, Agago, Otuke, Alebtong, Dokolo, Amolatar, Kiryandongo, Nakasongola, Masindi Districts. We also reach out with very good signal, Nebbi, Amuru, Adjumani, Lamwo, Kitgum, Kotido, Abim, Napak, Amuru, Amuru, Katakwi, Soroti, Ngora, Serere," />
                    <meta name="twitter:image" content="https://radiodivine.co.ug/images/divine_logo.png" />
                </Helmet>
                <Top />
                <Navigator />
                
                <div className='entryNewsBanner'>
                    {news ? news.slice(0, 4).map(news =>(
                        <NewsBanner key={news.id} news = {news} />
                    )):
                    items2.map((item, index) => (<NewsbannerSkeleton key={index}/>))
                    }
                </div>
                <MissionVision />
                <div className='newsDiv'>
                    <h1 className='mb-3 ps-3'>News & Events</h1>
                    <AnimatePresence>
                    {loadingNews ?  items.map((item, index) => (<NewsSkeleton key={index}/>)) :
                        <>
                            {news && 
                            (
                                news.length > 0 && news.slice(3, news.length).map((data, index) => {
                                return(
                                    <div className='flex'>
                                        <News data={data} key={data.id} index = {index}/>
                                    </div>
                                )
                                })
                            )
                            }
                        </>
                    }
                    </AnimatePresence>
                </div>
                <AnimatePresence>
                    <Programs />
                </AnimatePresence>
                <motion.div initial={{opacity: 0, x: -100}} whileInView={{opacity: 1, x: 0}} viewport={{ once: true }} transition={{duration: 1}} class="col-12 d-block d-md-flex gap-4 align-items-center centered mt-4 bg-light">
                    <div class="full pt-4 pb-4 ">
                        <h3 className='text-dark'>Need Our Services?</h3>
                        <p>At Radio Divine, we're more than just a frequency on your dial - we're your companion through the rhythms of life. Broadcasting 90.6 FM, we bring you a diverse range of programming that entertains, informs, and uplifts.</p>
                        <div className='d-flex justify-content-between align-items-center'>
                            <p className='text-dark fw-bold'><i class="fa-solid fa-reply text-danger pe-2"></i> Reply within 24 hours</p>
                            <p className='text-dark fw-bold'><i class="fa-solid fa-phone text-danger pe-2"></i> 24 hrs telephone support</p>
                        </div>
                        <p>Get in touch with us today to explore how our support, advisory, and advertising services can propel your business towards success.</p>
                        <div className='d-flex align-items-center gap-3'>
                            <i class="fa-solid fa-phone text-white p-4 fs-5 bg-danger text"></i>
                            <div>
                                <p className='m-0 text-dark fw-bold'>Call to ask any question</p>
                                <p className='m-0 text-danger fw-bold'>+256-758719555</p>
                            </div>
                        </div>
                    </div>
                    <ContactForm />
                </motion.div>
                    <OnlineStream />
                <Footer />
            </div>
    )
}

export default Entry
//<Whatsapp />