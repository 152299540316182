import React from 'react'
import "../css/Footer.css"
import { Link } from 'react-router-dom'
import Socials from './Socials'
import { motion } from 'framer-motion'

const Footer = () => {
  return (
    <footer>
        <div className='d-block d-md-flex gap-4 top'>
            <div>
                <h5 className='text-dark'><span>About Us</span></h5>
                <p>Radio Divine (90.6 Fm) is a non-sectarian, nonpartisan, development oriented Radio station based in Western Ward, Apac town council Apac town council; blessed with super qualified personnel among whom are Journalists and administrators who are self started, and result oriented. </p>
            </div>
            <div>
                <h5 className='text-dark'><span>Contacts</span></h5>
                <ul>
                    <li >
                        <span><i class="fa-solid fa-house"></i></span>
                        <span><Link to={"https://radiodivine.co.ug"}>https://radiodivine.co.ug</Link></span>
                    </li>
                    <li>
                        <span><i class="fa-solid fa-phone-volume"></i></span>
                        <span>+256-759-310105</span>
                    </li>
                    <li>
                        <span><i class="fa-solid fa-envelope"></i></span>
                        <span>divinefm@gmail.com</span>
                    </li>
                    <li>
                        <span><i class="fa-solid fa-location-dot"></i></span>
                        <span>Divine Road, Hospital & Around Cell, Central Ward, Akere Division, Apac P.O Box 86, Apac</span>
                    </li>
                </ul>
            </div>
            <div>
                <h5 className='text-dark'><span>Menu</span></h5>
                <ul>
                    <li><i class="fa-solid fa-chevron-right"></i> <Link to={"/"}>Home</Link></li>
                    <li><i class="fa-solid fa-chevron-right"></i> <Link to={"/rates"}>Our Rates</Link></li>
                    <li><i class="fa-solid fa-chevron-right"></i> <Link to={"/staff"}>Team</Link></li>
                    <li><i class="fa-solid fa-chevron-right"></i> <Link to={"/news"}>News</Link></li>
                </ul>
            </div>
            <div className=''>
                <h5 className='text-dark'><span>Twitter Feed</span></h5>
                {/*<Socials />*/}
            </div>
        </div>
        <div className='bottom'>
            <h5 className='text-center'>Copyright &copy; {new Date().getFullYear()} Divine Radio</h5>
            <div className='d-flex justify-content-center align-items-center'>
                <div className='d-none gap-2'>
                    <p onClick={()=>window.location.href="/"}><i class="fa-solid fa-chevron-right"></i> Home</p>
                    <p onClick={()=>window.location.href="/rates"}><i class="fa-solid fa-chevron-right"></i> Rates</p>
                    <p onClick={()=>window.location.href="/staff"}><i class="fa-solid fa-chevron-right"></i> Team</p>
                    <p onClick={()=>window.location.href="/news"}><i class="fa-solid fa-chevron-right"></i> News</p>
                </div>
                <div>
                    <motion.p
                        initial={{scale: 1}}
                        whileHover={{scale: 1.1}}>
                        <a className='text-white' href='https://cynaut-tech.com'>Powered by Cynaut Technologies</a>
                    </motion.p>
                </div>
                <div className='d-none gap-2'>
                    <i class="fa-brands fa-facebook"></i>
                    <i class="fa-brands fa-instagram"></i>
                    <i class="fa-brands fa-twitter"></i>
                    <i class="fa-brands fa-youtube"></i>
                </div>
            </div>
        </div>
    </footer>
  )
}


export default Footer