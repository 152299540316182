import React, { useState, useEffect, useRef } from 'react';
import useApiRequest from './useApiRequest';
import { domain } from './Domain';
import "../css/CategoryComponent.css";

const ClubGalleryComponent = () => {
  const { postRequest, getRequest, putRequest, deleteRequest } = useApiRequest();
  const inputRef = useRef();
  const [galleries, setGalleries] = useState([]);
  const [clubs, setClubs] = useState([]);
  const [selectedGalleryId, setSelectedGalleryId] = useState(null);
  const [selectedClubId, setSelectedClubId] = useState('');
  const initialFormState = {
    club: '',
    caption: '',
    image: null,
  };
  const [form, setForm] = useState(initialFormState);

  useEffect(() => {
    fetchGalleries();
    fetchClubs();
  }, []);

  const fetchGalleries = async () => {
    const response = await getRequest(`${domain}/gallery`);
    setGalleries(response);
  };

  const fetchClubs = async () => {
    const response = await getRequest(`${domain}/clubs`);
    setClubs(response);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleFileChange = (e) => {
    setForm({ ...form, image: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    for (const key in form) {
      if (key === "image") {
        if (form[key] !== null) {
          formData.append(key, form[key]);
        }
      } else {
        formData.append(key, form[key]);
      }
    }
    if (selectedGalleryId) {
      await putRequest(`${domain}/gallery/${selectedGalleryId}`, formData);
    } else {
      await postRequest(`${domain}/gallery`, formData);
    }
    setForm(initialFormState);
    setSelectedGalleryId(null);
    fetchGalleries();
  };

  const handleUpdate = (gallery) => {
    setForm({
      club: gallery.club,
      caption: gallery.caption,
      image: null, // Reset the file input since we cannot prepopulate file input
    });
    setSelectedGalleryId(gallery.id);
    inputRef.current.focus();
  };

  const handleCancelEdit = () => {
    setSelectedGalleryId(null);
    setForm(initialFormState);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      await deleteRequest(`${domain}/gallery/${id}`);
      fetchGalleries();
    }
  };

  const handleClubFilterChange = (e) => {
    setSelectedClubId(e.target.value);
  };

  const filteredGalleries = selectedClubId
    ? galleries.filter(gallery => gallery.club === parseInt(selectedClubId))
    : galleries;

  return (
    <div className='clubGalleryComponent'>
      <h5>Club Gallery</h5>
      <form onSubmit={handleSubmit}>
        <div className='label d-flex gap-4'>
          <label>Club:</label>
          <select name="club" value={form.club} onChange={handleInputChange}>
            <option value="">Select Club</option>
            {clubs.map(club => (
              <option key={club.id} value={club.id}>{club.name}</option>
            ))}
          </select>
        </div>
        <div className='label d-flex gap-4'>
          <label>Caption:</label>
          <input type="text" name="caption" placeholder='Image Caption' ref={inputRef} value={form.caption} onChange={handleInputChange} />
        </div>
        <div className='label d-flex gap-4'>
          <label>Image:</label>
          <input type="file" name="image" className='border-0' onChange={handleFileChange} />
        </div>
        <button type="submit" className='btn btn-sm btn-success'>{selectedGalleryId ? 'Update' : 'Add Image'}</button>
        {selectedGalleryId && (
          <button type='button' onClick={handleCancelEdit} className='btn btn-sm btn-danger ms-2'>Cancel Update</button>
        )}
      </form>

      <h5 className='mt-3'>Filter Gallery by Club</h5>
      <div className='label d-flex gap-4'>
        <label>Filter by Club:</label>
        <select value={selectedClubId} onChange={handleClubFilterChange}>
          <option value="">All Clubs</option>
          {clubs.map(club => (
            <option key={club.id} value={club.id}>{club.name}</option>
          ))}
        </select>
      </div>

      <h5 className='mt-3'>Existing Gallery</h5>
      <ul className='p-0 m-0 d-flex gap-2 flex-wrap'>
        {filteredGalleries.length > 0 ? filteredGalleries.map(gallery => (
          <li key={gallery.id} style={{width: "24%", listStyle:"none"}} className='gap-2 justify-content-between align-items-center border-bottom p-2 mb-2 rounded'>
            <div>
              <h2 className='fs-6 fw-bold'>{gallery.club.name}</h2>
              <p>{gallery.caption}</p>
              <img src={domain + gallery.image} style={{ width: "100%", height: 150 }} alt={gallery.caption} />
            </div>
            <div className='d-flex gap-2 justify-content-center p-2'>
              <button onClick={() => handleUpdate(gallery)} className='btn btn-sm btn-danger'>Edit</button>
              <button onClick={() => handleDelete(gallery.id)} className='btn btn-sm btn-success'>Delete</button>
            </div>
          </li>
        )):
        <p>No Data found</p>}
      </ul>
    </div>
  );
};

export default ClubGalleryComponent;
