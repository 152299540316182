import React, {useState, useEffect} from 'react'
import "../css/Register.css"
import { domain } from './Domain';
import useApiRequest from './useApiRequest'
const Register = () => {
    const { postRequest, response } = useApiRequest()
    const [loading, setLoading] = useState(false)
    const initial = {
        username: '',
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        confirmPassword: '',
        regCategory: '',
        dob: "",
        gender: "",
        contact: "",
        profile: null
    }
    const [formData, setFormData] = useState(initial);
    const [isPasswordMatch, setIsPasswordMatch] = useState(true); // New state for password match
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        const errors = validateForm(formData);
        setFormErrors(errors);
        setIsPasswordMatch(formData.password === formData.confirmPassword);
    }, [formData]);
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    
    const handleFileChange = (e) => {
        setFormData({ ...formData, profile: e.target.files[0] });
    };
    
    const handleSubmit = async(e) => {
        e.preventDefault();
        const errors = validateForm(formData);
        if (Object.keys(errors).length === 0) {
            // Submit the form data
            console.log(formData);

            setLoading(true)
            const formDatas = new FormData()
            for (const key in formData) {
                if(key === "firstname"){
                    formDatas.append("first_name", formData[key]);
                }else if(key === "lastname"){
                    formDatas.append("last_name", formData[key]);
                }else{
                    formDatas.append(key, formData[key]);
                }
                
            }

            const response = await postRequest(`${domain}/accounts/signup`, formDatas)
            console.log(response)

            setLoading(false)

            //resetiing the form after submission
            setFormData(initial)
            e.target.reset()
        } else {
            setFormErrors(errors);
        }
    };
    
    const validateForm = (data) => {
        let errors = {};
        for (let key in data) {
          if (data[key] === '') {
            errors[key] = `${key} is required`;
          }
        }
        if (data.password !== data.confirmPassword) {
          errors.confirmPassword = 'Passwords do not match';
        }
        return errors;
    };
    
    return (
        <div id='registerForm' className='animate__animated animate__fadeIn'>
            <form onSubmit={handleSubmit}>
                <h2 class="fw-bold fs-5 pt-2 pb-2 text-center">User Registration Form</h2>
                <div class="border rounded mb-2 p-3">
                    <div className='d-flex gap-2'>
                        <div className='w-50 flex-grow'>
                            <label>First Name:</label>
                            <input type="text" name="firstname" placeholder='firstname' value={formData.firstname} onChange={handleChange} />
                            {formErrors.firstname && <span>{formErrors.firstname}</span>}
                        </div>
                        <div className='w-50 flex-grow'>
                            <label>Last Name:</label>
                            <input type="text" name="lastname" placeholder='lastname' value={formData.lastname} onChange={handleChange} />
                            {formErrors.lastname && <span>{formErrors.lastname}</span>}
                        </div>
                    </div>
                    <div>
                        <label>Username:</label>
                        <input type="text" name="username" placeholder='username' value={formData.username} onChange={handleChange} />
                        {formErrors.username && <span>{formErrors.username}</span>}
                    </div>
                    <div className='d-flex gap-2'>
                        <div className='w-50 flex-grow'>
                            <label className="form-label">Date of Birth</label>
                            <input type='date' placeholder='Enter your dob' name='dob' onChange={handleChange} value={formData.dob} />
                            {formErrors.dob && <span>{formErrors.dob}</span>}
                        </div>
                        <div className='w-50 flex-grow'>
                            <label className="form-label">Gender</label>
                            <select className='w-100' name='gender' onChange={handleChange} value={formData.gender}>
                                <option>Select Your Gender</option>
                                <option value={"male"}>Male</option>
                                <option value={"female"}>Female</option>
                            </select>
                            {formErrors.gender && <span>{formErrors.gender}</span>}
                        </div>
                    </div>
                    <div>
                        <label>Email:</label>
                        <input type="email" name="email" placeholder='email address' value={formData.email} onChange={handleChange} />
                        {formErrors.email && <span>{formErrors.email}</span>}
                    </div>
                    <div>
                        <label className="form-label">phone number</label>
                        <input type='text' placeholder='Phone number' name='contact' onChange={handleChange} value={formData.contact} />
                        {formErrors.contact && <span>{formErrors.contact}</span>}
                    </div>
                    <div className='d-flex gap-2'>
                        <div className='w-50'>
                            <label>Password:</label>
                            <input type="password" name="password" placeholder='set password' value={formData.password} onChange={handleChange} />
                            {formErrors.password && <span>{formErrors.password}</span>}
                        </div>
                        <div className='w-50'>
                            <label>Confirm Password:</label>
                            <input type="password" name="confirmPassword" placeholder='confirm set password' value={formData.confirmPassword} onChange={handleChange} />
                            {formErrors.confirmPassword && <span>{formErrors.confirmPassword}</span>}
                        </div>
                    </div>
                    <div>
                        <label>Registration Category:</label>
                        <select name="regCategory" className='w-100' value={formData.regCategory} onChange={handleChange}>
                            <option value="" disabled>Select a category</option>
                            <option value="User">User</option>
                            <option value="Employee">Employee</option>
                            <option value="Staff">Staff</option>
                        </select>
                    </div>
                    {formErrors.regCategory && <span>{formErrors.regCategory}</span>}
                    <div>
                        <label>Profile Picture:</label>
                        <input type="file" className='border-0' accept="image/*" onChange={handleFileChange} />
                    </div>
                    <button disabled={!isPasswordMatch} className={`btn w-100 ${!isPasswordMatch === false && "btn text-white bg-secondary"}`}  style={{ borderBottom: "5px solid var(--background)" }} type="submit">{loading ? "Submitting Registration Data":"Register Now"}</button>
                </div>
            </form>
        </div>
        );
    };

export default Register