import logo from './logo.svg';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import { ActivePageProvider } from './components/ActivePageContext';
import Entry from './components/Entry';
import 'animate.css';
import About from './components/About';
import NewsPage from './components/NewsPage';
import Contact from './components/Contact';
import Advertise from './components/Advertise';
import NewsDetails from './components/NewsDetails';
import Staff from './components/Staff';
import ChatRoom from './components/Chatroom';
import Clubs from './components/Clubs';
import Login from './components/Login'
import Register from './components/Register';
import ForgotPassword from './components/ForgotPassword';
import PasswordResetForm from './components/PasswordResetForms';
import Dashboard from './components/Dashboard';
import PrivateRoutes from './components/ProtectedRoute';
import { ActiveStreamProvider } from './components/StreamPlayerContext';

function App() {
  return (
    <div className="App">
      <ActivePageProvider>
        <ActiveStreamProvider>
          <Routes>
            <Route path='/' element={<Entry />}/>
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/accounts/password-reset/:slug' element={<PasswordResetForm />} />
            <Route path='/about' element={<About />}/>
            <Route path='/rates' element={<Advertise />} />
            <Route path='/news' element={<NewsPage />} />
            <Route path='/news/:name' element={<NewsDetails />} />
            <Route path='/contact-us' element={<Contact />} />
            <Route path='/staff' element={<Staff />} />
            <Route path='/clubs' element={<Clubs />} />
            <Route path='/chat-room' element={<ChatRoom />} />
            <Route element={<PrivateRoutes />}>
              <Route path='/dashboard' element={<Dashboard />} />
            </Route>
          </Routes>
        </ActiveStreamProvider>
       </ActivePageProvider>
    </div>
  );
}

export default App;
