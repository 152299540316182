import React from 'react'
import { motion } from 'framer-motion'

const MissionVision = () => {
  return (
    <motion.div
        initial={{opacity: 0, scale: 0}}
        animate={{opacity: 1, scale: 1}}
        transition={{type: 'spring', stiffness: 300, damping: 90}}
        >
        <div className='pt-4 pb-4 container-lg slogan'>
            <h3 className='text-center'>Simplifying Your Communication.</h3>
            <p className='w-100 w-md-75 m-auto'>Radio Divine 90.6 Fm signal has a radium of about 250 Km. with about 7.2 million people (listeners), With excellent coverage in Apac, Lira, Oyam, Kole, Gulu, Pader, Nwoya, Agago, Otuke, Alebtong, Dokolo, Amolatar, Kiryandongo, Nakasongola, Masindi Districts. We also reach out with very good signal, Nebbi, Amuru, Adjumani, Lamwo, Kitgum, Kotido, Abim, Napak, Amuru, Amuru, Katakwi, Soroti, Ngora, Serere, Kayunga, Luwero, Nakaseke, Nakasongola, Hoima, and Bulisa Districts.</p>
        </div>
        <motion.div className='centered' initial = {{opacity: 0, y: 100}} viewport={{ once: true }} whileInView={{opacity: 1, y: 0}} transition={{duration: 1, type: 'spring', stiffness: 200, damping: 10}}>
            <div className='mb-2 mb-md-4'>
                <h3 className='text-dark mb-2'>Why Radio Divine</h3>
                <p>The goal of Radio Divine is to promote and mobilize the communities to proactively participate in development processes together with other similar-minded organizations and stakeholders such as the Government, None Governmental Organizations (NGOs), CBOs, and development partners both locally and internationally; in order to create a socially inclusive environment where all young people are engaged, informed and inspired to take on the challenges in their development processes as the route to achieving their ambitions and dreams.</p>
                <div className='bg-light p-4 border rounded'>
                    <h4 className='fs-5 fw-bold text-dark'>Our Vision</h4>
                    <p>Radio Divine’s vision is making community participation in development processes a reality.</p>
                    <h4 className='fs-5 fw-bold text-dark'>core values</h4>
                    <ul>
                        <li>Radio Divine educates informs and entertains people of different backgrounds while promoting the wellbeing of our community.</li>
                        <li>Radio Divine provides distinct means of education for listeners through well researched topics covering health, women’s rights, domestic and family life, agriculture, community development, schools, and business development among others; these topics reach out via programmes   such as Spot ads, Dramas, Dj Mentions, Talk shows, Features, and News.</li>
                        <li>Radio Divine news coverage has rich reputation for its complete National and World-wide news coverage.</li>
                    </ul>
                </div>
            </div>
        </motion.div>
    </motion.div>
  )
}

export default MissionVision