import React, {useEffect, useState} from 'react'
import "../css/Socials.css"

export const FacebookPage = () => {
    useEffect(() => {
        // Load the Facebook SDK asynchronously
        (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v13.0';
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }, []);

    return (
        <div className="fb-page" data-href="https://www.facebook.com/cynauttechnologies" data-tabs="timeline" data-width="500" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true">
            <blockquote cite="https://www.facebook.com/cynauttechnologies" className="fb-xfbml-parse-ignore">
                <a href="https://www.facebook.com/cynauttechnologies">Devine Radio FM</a>
            </blockquote>
        </div>
    );
};


const Socials = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://platform.twitter.com/widgets.js';
        script.charset = 'utf-8';
        script.async = true;
        document.body.appendChild(script);
        
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    return (
        <a className="twitter-timeline" href="https://twitter.com/Makerere" data-width = "100%" data-height="220">
            Tweets by Makerere
        </a>
    )
}
export default Socials