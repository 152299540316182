import React, {useState} from 'react'
import { Link, json } from 'react-router-dom'
import "../css/Dashboard.css"
import "../css/Home.css"
import { useLoginContext } from './LoginContext'
import { domain } from './Domain'
import { useNavigate } from 'react-router-dom';
import useApiRequest from './useApiRequest'
import Profile from './Profile'
import ProgramComponent from './ProgramComponent'
import ScheduleComponent from './ScheduleComponent'
import PeopleComponent from './PeopleComponent'
import NewsComponent from './NewsComponent'
import ClubComponent from './ClubComponent'
import ClubGalleryComponent from './ClubGalleryComponent'

const LandingPage = () => {
    return (
        <div className="landing-page">
            <header>
                <div className="container">
                    <h1>Welcome to Radio Divine Admin Console</h1>
                    <p>Manage All Dynamic Website Content From Here</p>
                </div>
            </header>
            <main>
                <section className="about">
                    <div className="container">
                        <p>Introducing an innovative system designed to revolutionize inventory management and customer interaction, the Stock Record and Inquiry Management System (SRIMS) represents a cutting-edge solution tailored for modern business needs. SRIMS seamlessly integrates advanced stock recording capabilities, inquiry creation and storage functionalities, and automated performance statistics generation.</p>
                        <p>This system not only simplifies the process of managing stock records but also enhances customer service by providing a robust platform for creating and tracking inquiries. Moreover, SRIMS leverages automation to deliver real-time performance insights, empowering us to make informed decisions swiftly and efficiently. Designed with scalability and user-friendliness in mind, SRIMS promises to be the cornerstone of operational efficiency for our businesses across various industries.</p>
                    </div>
                </section>
                <section className="contact">
                    <div className="container">
                        <h2>Contact Us</h2>
                        <p>If you have any questions or would like to learn more about our services, please contact us at:</p>
                        <p>Email: contact@universityexam.com | Phone: 123-456-7890</p>
                    </div>
                </section>
            </main>
        </div>
    );
};

const Dashboard = () => {
    const { postRequest, response } = useApiRequest()
    const navigate = useNavigate();
    const initial = {
        id: null, 
        token: null, 
        name: null, 
        date: null,
        image: null,
        role: null,
        is_super:null,
        user_type:null
    }
    const { userInfo, setUserInfo, setIsLoggedIn } = useLoginContext();
    const [active, setActive] = useState(null)
    const handleClick = (e) => {
        setActive(e)
    }

    const handleLogOut = async() => {
        const response = await postRequest(`${domain}/accounts/logout`, {})
        if(response['message'] === "logout successfull"){
            setUserInfo(initial)
            localStorage.setItem('isLoggedIn', false)
            setIsLoggedIn(false)
            navigate("/")
        }
    }

    return (
        <>
            <div className="home-container">
                <div className="side-panels">
                    <div className='d-none d-md-flex flex-column justify-content-center align-items-center pb-3 pt-3'>
                        <img src={domain+`${userInfo.image}`} style={{width:150, height:150, objectFit:"cover", objectPosition:"center"}} className='img-thumbnail rounded-circle img-fluid' alt='userProfile' />
                        <figcaption>{userInfo.name}</figcaption>
                        <small className='text-muted'>Registered on {new Date(userInfo.date).toLocaleString()}</small>
                    </div>
                
                    <Link onClick={()=>handleClick(null)}>Home</Link>
                    <Link onClick={()=>handleClick(0)}>Program Management</Link>
                    <Link onClick={()=>handleClick(1)}>Schedule Management</Link>
                    <Link onClick={()=>handleClick(2)}>Staff Management</Link>
                    <Link onClick={()=>handleClick(3)}>News Management</Link>
                    <Link onClick={()=>handleClick(4)}>Clubs Management</Link>
                    <Link onClick={()=>handleClick(6)}>My Profile</Link>
                    <Link onClick={handleLogOut} className='bg-danger text-white'>Logout of the system</Link>
                </div>
                <div className="scrollable-contents">
                    {active in [null, 1,2,3,4,5,6] &&
                        <div className="bg-body-tertiary p-4 text-center mb-3 position-sticky top-0">
                            <h1 className='fs-3'>Radio Divine Admin Console</h1>
                            <p>All Website Management</p>
                        </div>
                    }
                    { active === null && <LandingPage /> }
                    { active === 0 && <ProgramComponent /> }
                    { active === 1 && <ScheduleComponent /> }
                    { active === 2 && <PeopleComponent /> }
                    { active === 3 && <NewsComponent /> }
                    { active === 4 && <ClubComponent  setActive={setActive}/> }{/* club gallery set in club component active */}
                    { active === 5 && <ClubGalleryComponent /> }
                    { active === 6 && <Profile /> }
                </div>
            </div>
        </>
        
    )
}

export default Dashboard