import React, { useState, useEffect, useRef } from 'react';
import useApiRequest from './useApiRequest';
import { domain } from './Domain';
import "../css/CategoryComponent.css";

const ScheduleComponent = () => {
    const inputRef = useRef()
    const { postRequest, getRequest, putRequest, deleteRequest } = useApiRequest();
    const [schedules, setSchedules] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [selectedScheduleId, setSelectedScheduleId] = useState(null);
    const initialFormState = {
        program: '',
        title: '',
        time_from: '',
        time_to: '',
    };
    const [form, setForm] = useState(initialFormState);

    useEffect(() => {
        fetchSchedules();
        fetchPrograms();
    }, []);

    const fetchSchedules = async () => {
        const response = await getRequest(`${domain}/schedules`);
        setSchedules(response);
    };

    const fetchPrograms = async () => {
        const response = await getRequest(`${domain}/programs`);
        setPrograms(response);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (selectedScheduleId) {
            await putRequest(`${domain}/schedules/${selectedScheduleId}`, form);
        }else {
            await postRequest(`${domain}/schedules`, form);
        }
        setForm(initialFormState);
        setSelectedScheduleId(null);
        fetchSchedules();
    };

    const handleUpdate = (schedule) => {
        setForm({
            program: schedule.program,
            title: schedule.title,
            time_from: schedule.time_from,
            time_to: schedule.time_to,
        });
        setSelectedScheduleId(schedule.id);
    };

    const handleCancelEdit = () => {
        setSelectedScheduleId(null);
        setForm(initialFormState);
        inputRef.current.focus()
    };

    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            await deleteRequest(`${domain}/schedules/${id}`);
            fetchSchedules();
        }
    };

    return (
        <div className='scheduleComponent'>
            <h5>Schedules</h5>
            <form onSubmit={handleSubmit}>
                <div className='label d-flex gap-4'>
                    <label>Program:</label>
                    <select name="program" value={form.program} onChange={handleInputChange}>
                        <option value="">Select Program</option>
                        {programs.map(program => (
                            <option key={program.id} value={program.id}>{program.title}</option>
                        ))}
                    </select>
                </div>
                <div className='label d-flex gap-4'>
                    <label>Title:</label>
                    <input type="text" name="title" ref={inputRef} placeholder='Schedule title' value={form.title} onChange={handleInputChange} />
                </div>
                <div className='label d-flex gap-4'>
                    <label>Time From:</label>
                    <input type="time" name="time_from" value={form.time_from} onChange={handleInputChange} />
                </div>
                <div className='label d-flex gap-4'>
                    <label>Time To:</label>
                    <input type="time" name="time_to" value={form.time_to} onChange={handleInputChange} />
                </div>
                <button type="submit" className='btn btn-sm btn-success'>{selectedScheduleId ? 'Update Schedule' : 'Create Schedule'}</button>
                {selectedScheduleId && (
                    <button type='button' onClick={handleCancelEdit} className='btn btn-sm btn-danger ms-2'>Cancel Update</button>
                )}
            </form>

            <h5 className='mt-3'>Existing Schedules</h5>
            <ul className='p-0 m-0'>
                {schedules.map(schedule => (
                <li key={schedule.id} className='d-flex justify-content-between align-items-center border-bottom p-2 mb-2 rounded'>
                    <div>
                        <h2 className='fs-6 fw-bold'>{schedule.title}</h2>
                        <p>{schedule.program.title}</p>
                        <p>{schedule.time_from} - {schedule.time_to}</p>
                    </div>
                    <div className='d-flex gap-2'>
                        <button onClick={() => handleUpdate(schedule)} className='btn btn-sm btn-danger'>Edit</button>
                        <button onClick={() => handleDelete(schedule.id)} className='btn btn-sm btn-success'>Delete</button>
                    </div>
                </li>
                ))}
            </ul>
        </div>
    );
};

export default ScheduleComponent;
