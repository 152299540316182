import React, {useEffect, useState} from 'react'
import Footer from './Footer'
import Navigator from './Navigator'
import Top from './Top'
import PageBanner from './PageBanner'
import useSmoothScrollToTop from './useSmoothScroll'
import "../css/Advertise.css"
import { motion } from 'framer-motion'
import Loader from './Loader'
import { Helmet } from 'react-helmet';

const Advertise = () => {
  const timeSlots = [
    {
      timeSlot: "Morning PRIME",
      timeRange: "05:00 am - 10:00 am",
      "30 Seconds": "30,000/=",
      "45 Seconds": "35,000/=",
      "60 Seconds": "40,000/=",
      "90 Seconds": "55,000/="
    },
    {
      timeSlot: "STANDARD",
      timeRange: "11:00 am - 4:00 pm",
      "30 Seconds": "22,000/=",
      "45 Seconds": "28,000/=",
      "60 Seconds": "30,000/=",
      "90 Seconds": "40,000/="
    },
    {
      timeSlot: "Evening PRIME",
      timeRange: "05:00 pm - 10:00 pm",
      "30 Seconds": "30,000/=",
      "45 Seconds": "35,000/=",
      "60 Seconds": "40,000/=",
      "90 Seconds": "50,000/="
    },
    {
      timeSlot: "Off PEAK",
      timeRange: "10:00 pm - 05:00 am",
      "30 Seconds": "20,000/=",
      "45 Seconds": "25,000/=",
      "60 Seconds": "28,000/=",
      "90 Seconds": "32,000/="
    }
  ];
  
  const announcements = [
    {
      type: "Official Announcement",
      rate: "10,000/="
    },
    {
      type: "Official Special Announcement",
      rate: "20,000/="
    },
    {
      type: "Personal Announcement",
      rate: "7,000/="
    },
    {
      type: "Special Personal Announcement",
      rate: "14,000/="
    }
  ];

  const talkShows = [
    {
      duration: "15 Minutes",
      rate: "300,000/="
    },
    {
      duration: "30 Minutes",
      rate: "600,000/="
    },
    {
      duration: "45 Minutes",
      rate: "800,000/="
    },
    {
      duration: "60 Minutes (One hour)",
      rate: "1,300,000/="
    },
    {
      duration: "01 Hour 30 minutes",
      rate: "1,500,000/="
    }
  ];

  const programs = [
    {
      name: "Prime time news bulletin",
      duration: "15 Minutes",
      rate: "3,500,000/=",
    },
    {
      name: "Sports news/Update",
      duration: "30 Minutes",
      rate: "2,200,000/=",
    },
    {
      name: "Business news",
      duration: "30 Minutes",
      rate: "1,200,000/=",
    },
    {
      name: "News headlines",
      duration: "02 Minutes",
      rate: "2,000,000/=",
    },
    {
      name: "Weather update",
      duration: "10 Minutes",
      rate: "1,200,000/=",
    },
    {
      name: "Morning Prime time shows",
      duration: "Per hour",
      rate: "4,500,000/=",
    },
    {
      name: "Standard time shows",
      duration: "Per hour",
      rate: "3,000,000/=",
    },
    {
      name: "Evening prime time shows",
      duration: "Per hour",
      rate: "4,000,000/=",
    },
    {
      name: "Off Peak time shows",
      duration: "Per hour",
      rate: "2,200,000/=",
    }
  ];

  useSmoothScrollToTop();

  const [ spinner, setSpinner ] = useState(true);
  useEffect(()=>{
      setTimeout(() => setSpinner(false), 500);
  },[])

  return (
    spinner ? <Loader /> : 
    <div>
        <Helmet>
          <title>Advertise With Us</title>
          <meta name="description" content="Advertise with radio Devine Fm" />
          <meta property="og:title" content="Advertise with radio Devine Fm" />
          <meta property="og:description" content="Advertise with radio Devine Fm" />
          <meta property="og:image" content="https://radiodivine.co.ug/images/divine_logo.png" />
          <meta property="og:url" content="https://radiodivine.co.ug/images/divine_logo.png" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Advertise with radio Devine Fm" />
          <meta name="twitter:description" content="Advertise with radio Devine Fm" />
          <meta name="twitter:image" content="https://radiodivine.co.ug/images/divine_logo.png" />
      </Helmet>
        <Top />
        <Navigator />
        <PageBanner page={'Advertise With Us'}/>
        <motion.div
          initial = {{x:-100, rotate: "2.5deg"}}
          animate = {{x: 0, rotate: "0deg"}}
          transition={{duration: 1, type: 'spring', stiffness: 200, damping: 10}}
          class="main_heading text-center">
          <h2 className='text-dark'>ADVERTISE WITH US</h2>
          <p class="large">All tailored towards your personal Entertainment and digital well being</p>
        </motion.div>
        <div className='Advertise bg-white'>
          <h3>ADVERTISING RATES.</h3>
          <p className='title'>1. SPOTS / ADVERTS</p>
          <motion.table initial = {{opacity: 0, y: 100}} viewport={{ once: true }} whileInView={{opacity: 1, y: 0}} transition={{duration: 1, type: 'spring', stiffness: 200, damping: 10}}>
              <thead>
                <tr>
                  <th rowSpan={2}>Timeslot Available</th>
                  <th colSpan={4} className='bg-dark text-center'>Length</th>
                </tr>
                <tr>
                  <th>30 seconds</th>
                  <th>45 seconds</th>
                  <th>60 seconds</th>
                  <th>90 seconds</th>
                </tr>
              </thead>
              <tbody>
                {timeSlots && timeSlots.map((item, index)=>(
                  <tr key={index}>
                    <td>{item.timeSlot}: {item.timeRange}</td>
                    <td>{item['30 Seconds']}</td>
                    <td>{item['45 Seconds']}</td>
                    <td>{item['60 Seconds']}</td>
                    <td>{item['90 Seconds']}</td>
                  </tr>
                ))}
                
              </tbody>
            </motion.table>
            <p className='title'>2. ANNOUNCEMENTS: Fifty (50) Words and below per reading</p>
            <motion.table initial = {{opacity: 0, y: 100}} viewport={{ once: true }} whileInView={{opacity: 1, y: 0}} transition={{duration: 1, type: 'spring', stiffness: 200, damping: 10}}>
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Rate</th>
                </tr>
              </thead>
              <tbody>
                {announcements && announcements.map((item, index)=>(
                  <tr key={index}>
                    <td>{item.type}</td>
                    <td>{item.rate}</td>
                  </tr>
                ))}
              </tbody>
            </motion.table>
            <p className='title'>3. DJ MENTION 25,000/= PER MENTION <span className='text-success'>(TALKSHOWS)</span></p>
            <motion.table initial = {{opacity: 0, y: 100}} viewport={{ once: true }} whileInView={{opacity: 1, y: 0}} transition={{duration: 1, type: 'spring', stiffness: 200, damping: 10}}>
              <thead>
                <tr>
                  <th>Duration</th>
                  <th>Rate</th>
                </tr>
              </thead>
              <tbody>
                {talkShows && talkShows.map((item, index)=>(
                  <tr key={index}>
                    <td>{item.duration}</td>
                    <td>{item.rate}</td>
                  </tr>
                ))}
              </tbody>
            </motion.table>
            <p className='title'>4. PROGRAM SPONSORSHIP</p>
            <motion.table initial = {{opacity: 0, y: 100}} viewport={{ once: true }} whileInView={{opacity: 1, y: 0}} transition={{duration: 1, type: 'spring', stiffness: 200, damping: 10}}>
              <thead>
                <tr>
                  <th>Program</th>
                  <th>Duration</th>
                  <th>Rate</th>
                </tr>
              </thead>
              <tbody>
                {programs && programs.map((item, index)=>(
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>{item.duration}</td>
                    <td>{item.rate}</td>
                  </tr>
                ))}
                
              </tbody>
            </motion.table>
          </div>
        <Footer />
    </div>
  )
}

export default Advertise