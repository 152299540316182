import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'
import "../css/PageBanner.css"
import { motion } from 'framer-motion'

const PageBanner = ({page, bg}) => {
  const bannerStyle = {
    background: `url(${bg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover', 
    backgroundPosition: 'center'
  };

  useEffect(()=>{
  }, [bg])
  return (
    <motion.div
      initial={{y: 20}}
      animate={{y: 0}}
      transition={{duration: 2, type: 'spring', stiffness: 200, damping: 100}}
      className={`bannerPage text-left`} style={bg ? bannerStyle : null}>
        <h1 className="page-title">{page}</h1>
        <nav aria-label="breadcrumb"></nav>
        <ol className="breadcrumb">
            <li><Link to="/" className='text-danger'>Home </Link><i className="fa-solid fa-angle-right pe-1"></i></li>
            <li className="active">{page}</li>
        </ol>
    </motion.div>
  )
}

export default PageBanner

//, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center center"}