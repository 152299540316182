import React, {useState, useEffect} from 'react'
import { domain } from './Domain'
import Footer from './Footer'
import Navigator from './Navigator'
import Top from './Top'
import PageBanner from './PageBanner'
//import useFetch from './UseFetch'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import useFetch from './useFetch'
import useSmoothScrollToTop from './useSmoothScroll'
import "../css/NewsDetails.css"
import { motion, AnimatePresence } from 'framer-motion'
import Loader from './Loader'
import {Helmet} from "react-helmet";


const NewsDetails = () => {
    useSmoothScrollToTop()
    const { name } = useParams()
    const {data, loading, error} = useFetch(`${domain}/news/${name}`);
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Smooth scroll behavior
        });

    },[name])

    const [ spinner, setSpinner ] = useState(true);
    useEffect(()=>{
        setTimeout(() => setSpinner(false), 500);
    },[spinner])

    return (
        spinner ? <Loader /> :
        <>
            {data && 
            <Helmet>
                <title>{"News Details - " + data.news.title}</title>
                <meta name="description" content={data.news.title} />
                <meta property="og:title" content={data.news.title} />
                <meta property="og:description" content={data.news.details} />
                <meta property="og:image" content={domain+data.news.cover} />
                <meta property="og:url" content={domain+data.news.cover} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={data.news.title} />
                <meta name="twitter:description" content={data.news.details} />
                <meta name="twitter:image" content={domain+data.news.cover} />
            </Helmet>
            }
            <Top />
            <Navigator />
            
            <PageBanner page={'News Details'} bg={data && domain+data.news.cover}/>
            <AnimatePresence>
                <motion.div initial = {{opacity: 0, scale: 0}} animate = {{opacity: 1, scale: 1}} exit={{ opacity: 0, scale: 0 }} className='NewsDetails'>
                    <div className='inners row'>
                        <div className='col-lg-8'>
                            {error && <p>{error}</p>}
                            {loading && <p className='fw-bold'>Loading data ....</p>}
                            {data && 
                                <div>
                                    <h2 className='pt-4 fs-4 pb-2'><span className='fw-bolder'>{data.news.title}</span></h2>
                                    <img className='img-fluid pb-2' src={domain+data.news.cover}/>
                                    <p className='fw-light' dangerouslySetInnerHTML={{ __html: data.news.details }}></p>
                                </div>
                            }
                        </div>
                        <div className='col-lg-4'>
                            <div className='position-sticky top-0'>
                                <h1 className='fs-3 text-secondary pt-4 fw-bold' style={{fontFamily: "Playfair"}}><span className='fw-bolder'>News Feed</span></h1>
                                {error && <p>{error}</p>}
                                {loading && <p>Loading data ....</p>}
                                {data && data.other_news.slice(0, 10).map(data =>{
                                    return(
                                        <>
                                            <Link to={`/news/${data.slug}`} onClick={()=>setSpinner(!spinner)} className='d-flex gap-4 text-success border-bottom pt-2 pb-2'>
                                                <div className='w-25'>
                                                    <img src={domain+data.cover} className='w-100' alt='myImg'/>
                                                </div>
                                                <div className='w-75'>
                                                    {data.title} 
                                                    <br />
                                                    <small className='text-muted'>posted on - {new Date(data.created_at).toLocaleString()}</small>
                                                </div>
                                            </Link>
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </motion.div>
            </AnimatePresence>
            <Footer />
        </>
    )
}

export default NewsDetails