import React, { useState, useEffect, useRef } from 'react';
import useApiRequest from './useApiRequest';
import { domain } from './Domain';
import "../css/CategoryComponent.css";

const ClubComponent = ({ setActive }) => {
  const { postRequest, getRequest, putRequest, deleteRequest } = useApiRequest();
  const inputRef = useRef();
  const [clubs, setClubs] = useState([]);
  const [selectedClubId, setSelectedClubId] = useState(null);
  const initialFormState = {
    name: '',
    description: '',
  };
  const [form, setForm] = useState(initialFormState);

  useEffect(() => {
    fetchClubs();
  }, []);

  const fetchClubs = async () => {
    const response = await getRequest(`${domain}/clubs`);
    setClubs(response);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedClubId) {
      await putRequest(`${domain}/clubs/${selectedClubId}`, form);
    } else {
      await postRequest(`${domain}/clubs`, form);
    }
    setForm(initialFormState);
    setSelectedClubId(null);
    fetchClubs();
  };

  const handleUpdate = (club) => {
    setForm({
      name: club.name,
      description: club.description,
    });
    setSelectedClubId(club.id);
    inputRef.current.focus();
  };

  const handleCancelEdit = () => {
    setSelectedClubId(null);
    setForm(initialFormState);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      await deleteRequest(`${domain}/clubs/${id}`);
      fetchClubs();
    }
  };

  return (
    <div className='clubComponent'>
      <h5>Clubs</h5>
      <form onSubmit={handleSubmit}>
        <div className='label d-flex gap-4'>
          <label>Name:</label>
          <input type="text" name="name" placeholder='Club Name' ref={inputRef} value={form.name} onChange={handleInputChange} />
        </div>
        <div className='label d-flex gap-4'>
          <label>Description:</label>
          <textarea name="description" placeholder='Club Description' value={form.description} onChange={handleInputChange}></textarea>
        </div>
        <button type="submit" className='btn btn-sm btn-success'>{selectedClubId ? 'Update' : 'Create Club'}</button>
        {selectedClubId && (
          <button type='button' onClick={handleCancelEdit} className='btn btn-sm btn-danger ms-2'>Cancel Update</button>
        )}
        <button type="button" onClick={()=> setActive(5)} className='ms-2 btn btn-sm btn-primary'>Add Club Gallery</button>
      </form>

      <h5 className='mt-3'>Existing Clubs</h5>
      <ul className='p-0 m-0'>
        {clubs.map(club => (
          <li key={club.id} className='d-flex justify-content-between align-items-center border-bottom p-2 mb-2 rounded'>
            <div>
              <h2 className='fs-6 fw-bold'>{club.name}</h2>
              <p>{club.description}</p>
            </div>
            <div className='d-flex gap-2'>
              <button onClick={() => handleUpdate(club)} className='btn btn-sm btn-danger'>Edit</button>
              <button onClick={() => handleDelete(club.id)} className='btn btn-sm btn-success'>Delete</button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ClubComponent;
