import React, {useState} from 'react'
import "../css/NewsBanner.css"
import { domain } from './Domain'
import { Link } from 'react-router-dom'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export const NewsbannerSkeleton = () => {

    return(
        <div className="newBannerDiv">
                    <SkeletonTheme baseColor="#ddd3" highlightColor="#5555" >
            <div className='newsBanner'>
                <Skeleton height={300} width={"100%"} count={1} />
                <div>
                    <h4><Skeleton height={10} width={100} count={1} /></h4>
                    <h2><Skeleton height={10} width={"50%"} count={1} /></h2>
                    <h2><Skeleton height={10} width={"30%"} count={1} /></h2>
                    <small><Skeleton height={5} width={"40%"} count={1} /></small>
                </div>
            </div>
        </SkeletonTheme>
        </div>

    )
}

const NewsBanner = ({news}) => {
    const truncateString = (str, len)=> {
        return str.length > len ? str.substring(0, len) + "..." : str;
    }
    
    const [loaded, setLoaded] = useState(false);
    
    const handleImageLoad = () => {
        setLoaded(true);
    };
    const options = { 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric' 
    };
    return (
        <Link to={`/news/${news && news.slug}`} className='newBannerDiv'>
            <div className='newsBanner'>
                {!loaded && 
                    <div className='w-100 bg-secondary p-0 m-0' style={{height: "100%"}}>
                    </div>
                }
                <img 
                    src={news && domain+news.cover}
                    onLoad={handleImageLoad}
                    style={{ display: loaded ? 'block' : 'none' }} />
                <div>
                    <h4>Divine FM</h4>
                    <h2>{truncateString(news && news.title, 40)}</h2>
                    <small className='text-white'>By <span className='fw-bold'>{news && news.by}</span> <i class="fa-regular fa-clock"></i> {news && new Intl.DateTimeFormat('en-US', options).format(new Date(news.created_at))}</small>
                </div>
            </div>
        </Link>
    )
}

export default NewsBanner