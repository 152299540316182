import React, {useEffect, useState} from 'react'
import Footer from './Footer'
import Navigator from './Navigator'
import Top from './Top'
import PageBanner from './PageBanner'
import useSmoothScrollToTop from './useSmoothScroll'
import "../css/Contact.css"
import { motion } from 'framer-motion'
import Loader from './Loader'
import { Helmet } from 'react-helmet';


const Contact = () => {
    useSmoothScrollToTop();
    const [ spinner, setSpinner ] = useState(true);
    useEffect(()=>{
        setTimeout(() => setSpinner(false), 500);
    },[])
    return (
        spinner ? <Loader /> :
        <div>
            <Helmet>
                <title>Contact Us</title>
                <meta name="description" content="Welcome to our platform! We strive to provide you with the best experience possible. Our team is dedicated to delivering high-quality services tailored to meet your needs." />
                <meta property="og:title" content="Contact Us" />
                <meta property="og:description" content="Welcome to our platform! We strive to provide you with the best experience possible. Our team is dedicated to delivering high-quality services tailored to meet your needs." />
                <meta property="og:image" content="https://radiodivine.co.ug/images/divine_logo.png" />
                <meta property="og:url" content="https://radiodivine.co.ug/images/divine_logo.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Contact Us" />
                <meta name="twitter:description" content="Welcome to our platform! We strive to provide you with the best experience possible. Our team is dedicated to delivering high-quality services tailored to meet your needs." />
                <meta name="twitter:image" content="https://radiodivine.co.ug/images/divine_logo.png" />
            </Helmet>
            <Top />
            <Navigator />
            <PageBanner page={'Contact Us'}/>
            <motion.div 
                initial = {{x:-100, rotate: "2.5deg"}}
                animate = {{x: 0, rotate: "0deg"}}
                transition={{duration: 1, type: 'spring', stiffness: 200, damping: 10}} class="main_heading text-center">
                <h2 className='text-dark'>We are a call away</h2>
                <p class="large">Always ready to serve you no matter what!</p>
            </motion.div>
            <div className='row adress_cont m-auto'>
                <motion.div initial = {{opacity: 0, y: 100}} whileInView={{opacity: 1, y: 0}} transition={{duration: 1, type: 'spring', stiffness: 200, damping: 10}} class="col-md-6">
                    <h4>Contact Information</h4>
                    <p>Welcome to our platform! We strive to provide you with the best experience possible. Our team is dedicated to delivering high-quality services tailored to meet your needs.</p>
                    <div class="d-flex align-items-center gap-4">
                    <i class="fa fa-road" aria-hidden="true"></i>
                    <div className='w-100'>
                        <h4>Divine Road, Hospital & Around Cell, Central Ward, Akere Division</h4>
                        <p>Apac P.O Box 86, Apac (U)</p>
                        <hr />
                    </div>
                </div>
                <div class="d-flex align-items-center gap-4">
                    <i class="fa fa-user" aria-hidden="true"></i>
                    <div className='w-100'>
                        <h4>+256-758-719 555, +256-750-645472, +256-774-159360</h4>
                        <p>Mon-Fri 8:30am-6:30pm</p>
                        <hr/>
                    </div>
                </div>
                <div class="d-flex align-items-center gap-4">
                    <i class="fa fa-envelope" aria-hidden="true"></i>
                    <div className='w-100'>
                        <h4>90.6divinefm@gmail.com </h4>
                        <p>24/7 online support</p>
                        <hr />
                    </div>
                </div>
            </motion.div>
            <motion.div initial = {{opacity: 0, y: -100}} whileInView={{opacity: 1, y: 0}} transition={{duration: 1, type: 'spring', stiffness: 200, damping: 10}} className='col-md-6'>
                <iframe width="100%" className='bg-light' height="400" frameborder="0" style={{border:0}} src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBGY-aKP5KQbjJbf2B2mN6u9909uI5iv50&amp;q= Divine Road, Divine Radio, Central Ward, Akere Division" allowfullscreen="" title='location'></iframe>
            </motion.div>
            </div>
            <Footer />
        </div>
    )
}

export default Contact