import React, {useState, useEffect, useRef} from 'react'
import { Link } from 'react-router-dom'
import "../css/Top.css"
import io from "socket.io-client";
import { useActiveStream } from './StreamPlayerContext'



const Top = () => {
  const {opened, setOpened}= useActiveStream()

  const openStream = () => {
      setOpened(()=>!opened)
  }
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const togglePlay = async () => {
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const initializeAudio = async () => {
      const audioElement = new Audio('https://streams.radiomast.io/e834750f-ace2-41c1-9922-34d182933a6d');
      audioRef.current = audioElement;

      // Set up event listeners
      audioElement.addEventListener('ended', () => {
        setIsPlaying(false);
      });

      // Cleanup function to pause and remove the audio element when the component unmounts
      return () => {
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current = null;
        }
      };
    };

    initializeAudio();
  }, []);

  useEffect(() => {
    const toggleAudio = async () => {
      if (audioRef.current) {
        if (isPlaying) {
          try {
            await audioRef.current.play();
          } catch (error) {
            console.error("Error playing audio:", error);
            setIsPlaying(false); // Reset playing state if an error occurs
          }
        } else {
          audioRef.current.pause();
        }
      }
    };

    toggleAudio();
  }, [isPlaying]);

    return (
        <div className='row align-items-center top'>
            <div className='col-md-8'>
                <ul class="list-inline upper d-flex gap-4 m-0 pt-2 pb-2">
                    <li> <span class="topbar-label"><i className="fa fa-home"></i></span> <span class="topbar-hightlight">Akere Division, Apac P.O Box 86, Apac</span> </li>
                    <li> <span class="topbar-label"><i className="fa fa-envelope"></i></span> <span class="topbar-hightlight"><a href="mailto:divinefm@gmail.com">divinefm@gmail.com</a></span> </li>
                </ul>
            </div>
            <div className='col-md-4 d-flex justify-content-end'>
                <ul class="list-inline d-flex m-0 gap-3 pt-2 pb-2">
                    <li><Link className="fab fa-facebook" href="https://www.facebook.com/" title="Facebook" target="_blank"></Link></li>
                    <li><Link className="fab fa-twitter" href="https://twitter.com" title="Twitter" target="_blank"></Link></li>
                    <li><Link className="fab fa-linkedin" href="https://www.linkedin.com" title="LinkedIn" target="_blank"></Link></li>
                    <li><Link className="fab fa-instagram" href="https://www.instagram.com" title="Instagram" target="_blank"></Link></li>
                </ul>
                <div className='last pt-2 pb-2 ms-3'>
                    <Link onClick={openStream}><i class="fa-solid fa-play"></i> {opened ? 'Online Radio Listening' : 'Online Radio'}</Link>
                </div>
            </div>
        </div>
    )
}

export default Top