import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import "../css/Navigator.css"
import { useActivePage } from './ActivePageContext'
import { motion, AnimatePresence } from 'framer-motion'

const Navigator = () => {
    const { activePage, setActivePage } = useActivePage();
    const [isVisible, setIsVisible] = useState(false);
    const [isSticky, setIsSticky] = useState(false);

    const handlePageChange = (page) => {
        setActivePage(page);
    };
    const handleMenu = () => {
        if (isVisible) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }
    }

    useEffect(() => {
        const handleScroll = () => {
          const offset = window.scrollY;
          setIsSticky(offset > 100);
        };
    
        // Attach the event listener when the component mounts
        window.addEventListener('scroll', handleScroll);


        const path = window.location.pathname;
        const parts = path.split('/'); // Split the path by '/'
        const lastPart = parts[parts.length - 1]; // Get the last part of the path
    
        console.log('Last part of URL:', lastPart);
        if(lastPart=== ""){
            setActivePage("home");
        }else{
            setActivePage(lastPart);
        }
    
        // Detach the event listener when the component unmounts
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <motion.div
            initial={{ opacity: 0, x: '1%' }}
            animate={{ opacity: 1, x: 0 }}
            transition={{type: 'spring', stiffness: 200, damping: 10}}
            className={`nav-menu d-flex w-100 justify-content-between align-items-center ${isSticky ? 'animate__animated animate__fadeInDown sticky' : ''}`}>
            <motion.figure
                whileHover={{rotate: "0.5deg"}}
                whileTap={{scale: 0.85, rotate: "-0.5deg"}}
                transition={{type: 'spring', stiffness: 200, damping: 10}}
                className='d-flex align-items-center gap-2'>
                <img src='/images/divine_logo.png' />
                <figcaption className='fw-bold'>Radio Divine 90.6 Fm</figcaption>
            </motion.figure>
            <motion.span whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.9 }}><Link className='menu' onClick={handleMenu}><i class="fa-solid fa-bars"></i> Menu</Link></motion.span>
            <AnimatePresence>
                <motion.nav exit={{opacity: 0}} className={isVisible ? 'd-block animate__animated animate__fadeInLeft' :''}>
                    <Link to={'/'} onClick={() => handlePageChange('home')} className={activePage === 'home' ? 'activePage' : ''}>Home</Link>
                    <Link to={'/about'} onClick={() => handlePageChange('about')} className={activePage === 'about' ? 'activePage' : ''}>About Us</Link>
                    <Link to={'/rates'} onClick={() => handlePageChange('rates')} className={activePage === 'rates' ? 'activePage' : ''}>Rates</Link>
                    <Link to={'/news'} onClick={() => handlePageChange('news')} className={activePage === 'news' ? 'activePage' : ''}>News</Link>
                    <Link to={'/staff'} onClick={() => handlePageChange('staff')} className={activePage === 'staff' ? 'activePage' : ''}>Staff</Link>
                    <Link to={'/clubs'} onClick={() => handlePageChange('clubs')} className={activePage === 'clubs' ? 'activePage' : ''}>Clubs</Link>
                    <Link to={'/contact-us'} onClick={() => handlePageChange('contact-us')} className={activePage === 'contact-us' ? 'activePage' : ''}>Contact Us</Link>
                </motion.nav>
            </AnimatePresence>
        </motion.div>
    )
}

export default Navigator