import React, { useState, useEffect, useRef } from 'react';
import useApiRequest from './useApiRequest';
import { domain } from './Domain';
import "../css/CategoryComponent.css";

const PeopleComponent = () => {
  const { postRequest, getRequest, putRequest, deleteRequest } = useApiRequest();
  const inputRef = useRef();
  const [people, setPeople] = useState([]);
  const [selectedPersonId, setSelectedPersonId] = useState(null);
  const initialFormState = {
    name: '',
    image: null,
    position: '',
    brief_info: '',
    contact: '',
    facebook: '',
    twitter: '',
    instagram: '',
    whatsapp: '',
  };
  const [form, setForm] = useState(initialFormState);

  useEffect(() => {
    fetchPeople();
  }, []);

  const fetchPeople = async () => {
    const response = await getRequest(`${domain}/people`);
    setPeople(response);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleFileChange = (e) => {
    setForm({ ...form, image: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    for (const key in form) {
      if (key === "image") {
        if (form[key] !== null) {
          formData.append(key, form[key]);
        }
      } else {
        formData.append(key, form[key]);
      }
    }
    if (selectedPersonId) {
      await putRequest(`${domain}/people/${selectedPersonId}`, formData);
    } else {
      await postRequest(`${domain}/people`, formData);
    }
    setForm(initialFormState);
    setSelectedPersonId(null);
    fetchPeople();
  };

  const handleUpdate = (person) => {
    setForm({
      name: person.name,
      image: null, // Reset the file input since we cannot prepopulate file input
      position: person.position,
      brief_info: person.brief_info,
      contact: person.contact,
      facebook: person.facebook,
      twitter: person.twitter,
      instagram: person.instagram,
      whatsapp: person.whatsapp,
    });
    setSelectedPersonId(person.id);
    inputRef.current.focus();
  };

  const handleCancelEdit = () => {
    setSelectedPersonId(null);
    setForm(initialFormState);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      await deleteRequest(`${domain}/people/${id}`);
      fetchPeople();
    }
  };

  return (
    <div className='peopleComponent'>
      <h5>People</h5>
      <form onSubmit={handleSubmit} className='d-flex flex-wrap gap-4'>
        <div className='w-25 flex-grow-1'>
            <div className='label d-flex gap-4'>
            <label>Name:</label>
            <input type="text" name="name" ref={inputRef} placeholder='Name' value={form.name} onChange={handleInputChange} />
            </div>
            <div className='label d-flex gap-4'>
            <label>Image:</label>
            <input type="file" name="image" className='border-0' onChange={handleFileChange} />
            </div>
            <div className='label d-flex gap-4'>
            <label>Position:</label>
            <input type="text" name="position" placeholder='Position' value={form.position} onChange={handleInputChange} />
            </div>
            <div className='label d-flex gap-4'>
            <label>Brief Info:</label>
            <textarea name="brief_info" placeholder='Brief Info' value={form.brief_info} onChange={handleInputChange}></textarea>
            </div>
        </div>
        <div className='w-25 flex-grow-1'>
            <div className='label d-flex gap-4'>
                <label>Contact:</label>
                <input type="text" name="contact" placeholder='Contact' value={form.contact} onChange={handleInputChange} />
            </div>
            <div className='label d-flex gap-4'>
                <label>Facebook:</label>
                <input type="text" name="facebook" placeholder='Facebook' value={form.facebook} onChange={handleInputChange} />
            </div>
            <div className='label d-flex gap-4'>
                <label>Twitter:</label>
                <input type="text" name="twitter" placeholder='Twitter' value={form.twitter} onChange={handleInputChange} />
            </div>
            <div className='label d-flex gap-4'>
                <label>Instagram:</label>
                <input type="text" name="instagram" placeholder='Instagram' value={form.instagram} onChange={handleInputChange} />
            </div>
            <div className='label d-flex gap-4'>
                <label>WhatsApp:</label>
                <input type="text" name="whatsapp" placeholder='WhatsApp' value={form.whatsapp} onChange={handleInputChange} />
            </div>
        </div>
        <div className='w-100'>
            <button type="submit" className='btn btn-sm btn-success'>{selectedPersonId ? 'Update' : 'Create Person'}</button>
            {selectedPersonId && (
            <button type='button' onClick={handleCancelEdit} className='btn btn-sm btn-danger ms-2'>Cancel Update</button>
            )}
        </div>
      </form>

      <h5 className='mt-3'>Existing People</h5>
      <ul className='p-0 m-0'>
        {people.map(person => (
          <li key={person.id} className='d-flex justify-content-between align-items-center border-bottom p-2 mb-2 rounded'>
            <div>
              <h2 className='fs-6 fw-bold'>{person.name}</h2>
              <p>{person.position}</p>
              <p>{person.brief_info}</p>
              <p>{person.contact}</p>
              <p>{person.facebook}</p>
              <p>{person.twitter}</p>
              <p>{person.instagram}</p>
              <p>{person.whatsapp}</p>
              <img src={domain + person.image} style={{ width: 100 }} alt={person.name} />
            </div>
            <div className='d-flex gap-2'>
              <button onClick={() => handleUpdate(person)} className='btn btn-sm btn-danger'>Edit</button>
              <button onClick={() => handleDelete(person.id)} className='btn btn-sm btn-success'>Delete</button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PeopleComponent;
