import React, {useState, useEffect, useRef} from 'react'
import Footer from './Footer'
import Navigator from './Navigator'
import Top from './Top'
import PageBanner from './PageBanner'
import useSmoothScrollToTop from './useSmoothScroll'
import { domain } from './Domain'
import "../css/Staff.css"
import useFetch from './useFetch'
import { Link } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Loader from './Loader'
import { Helmet } from 'react-helmet';

function StaffMember({data, index}){
    const [loaded, setLoaded] = useState(false);

    const handleImageLoad = () => {
        setLoaded(true);
    };

    return(
        <motion.div key={data.id} class="staff bg-light"
            initial={{opacity: 0, scale: 0,  y: 50}}
            whileInView={{opacity: 1, scale: 1, y: 0}}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}>
            <img 
                onLoad={handleImageLoad}
                style={{ display: loaded ? 'block' : 'none' }}
                src={domain+data.image} 
                class="mx-auto" alt="user_image"/>
            {!loaded && 
                <Skeleton width={"100%"} height={300} />
            }
            <motion.div>
                <p className='fs-4 p-3 fw-bold m-0'>{data.name}</p>
                <p className='fs-6'>{data.position}</p>
                <p className='d-flex justify-content-center'>
                    <Link className={`btn btn-sm border-0 ${data.facebook === "" && "disabled"}` } ><i class="fa-brands fa-facebook"></i></Link>
                    <Link className={`btn btn-sm border-0 ${data.twitter === "" && "disabled"}`}><i class="fa-brands fa-twitter"></i></Link>
                    <Link className={`btn btn-sm border-0 ${data.instagram === "" && "disabled"}`}><i class="fa-brands fa-instagram"></i></Link>
                    <Link className={`btn btn-sm border-0 ${data.instagram === "" && "disabled"}`}><i class="fa-brands fa-whatsapp"></i></Link>
                </p>
            </motion.div>
        </motion.div>
    )
}

const Staff = () => {
    const [ spinner, setSpinner ] = useState(true);
    useEffect(()=>{
        setTimeout(() => setSpinner(false), 500);
    },[])
    useSmoothScrollToTop();
    const myDivRef = useRef(null);
    const {data: staff, loading, error} = useFetch(`${domain}/people`);
    const [currentPage, setCurrentPage] = useState(1);

    const itemsPerPage = 8

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = staff?.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(staff?.length / itemsPerPage);

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
        if (myDivRef.current) {
            // Scroll to the div
            myDivRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
        if (myDivRef.current) {
            // Scroll to the div
            myDivRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        spinner ? <Loader /> :
        <div>
            <Helmet>
                <title>Staff Members</title>
                <meta name="description" content="Meet Our Staff Members" />
                <meta property="og:title" content="Staff Members" />
                <meta property="og:description" content="Meet our staff members" />
                <meta property="og:image" content="https://radiodivine.co.ug/images/divine_logo.png" />
                <meta property="og:url" content="https://radiodivine.co.ug/images/divine_logo.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Staff Members" />
                <meta name="twitter:description" content="Meet Our Staff Members" />
                <meta name="twitter:image" content="https://radiodivine.co.ug/images/divine_logo.png" />
            </Helmet>
            <Top />
            <Navigator />
            <PageBanner initial={{opacity: 0}} page={'Our People'}/>
            <motion.div
                ref={myDivRef}
                initial = {{x:-100, rotate: "2.5deg"}}
                animate = {{x: 0, rotate: "0deg"}}
                transition={{duration: 1, type: 'spring', stiffness: 200, damping: 10}}
                class="main_heading text-center">
                <h2 className='text-dark'>MEET OUR STAFF MEMBERS</h2>
                <p class="large">Dedicated towards the best service provison</p>
            </motion.div>
            <motion.div
                initial={{scale: 0}}
                animate={{scale: 1}}
                className='d-flex flex-wrap staff_div pb-4'>
                {/* changed from data to currentItems for pagination purposes */}

                <AnimatePresence>
                {currentItems && (
                    currentItems.map((data, index) => (
                        <StaffMember key={index} data = {data} index = {index}/>
                    ))
                )}
                </AnimatePresence>
            </motion.div>
            {currentItems && currentItems.map((item, index) => (
                <div className='d-none' key={index}>{JSON.stringify(item)}</div>
            ))}
            <div className='d-flex justify-content-center align-items-center pb-4 gap-2 flex-wrap'>
                <p className='text-center w-100 fw-bold'>Showing page {currentPage} of {totalPages}</p>
                <motion.button whileHover={{scale: 0.85, rotate: "-2.5deg"}} onClick={handlePrevPage} className='btn btn-primary' disabled={currentPage === 1}>
                Prev
                </motion.button>
                <motion.button whileHover={{scale: 0.85, rotate: "2.5deg"}} onClick={handleNextPage} className='btn btn-success' disabled={currentPage === totalPages}>
                Next
                </motion.button>
            </div>
            <Footer />
        </div>
    )
}

export default Staff