import { useEffect, useState, useRef } from "react";
import io from "socket.io-client";
import Footer from './Footer'
import Navigator from './Navigator'
import Top from './Top'
import PageBanner from './PageBanner'
import useSmoothScrollToTop from './useSmoothScroll'
import { domain } from './Domain'
import "../css/ChatRoom.css"

const socket = io.connect("http://127.0.0.1:3001");

const ChatRoom = () => {
    const inputEntry = useRef()
    const lastMesage = useRef(null)

    useSmoothScrollToTop();
    //const {data: staff, loading, error} = useFetch(`${domain}/staff`);
      //Room State
    const [room, setRoom] = useState("");
    const [username, setUsername] = useState("");
    const [userCount, setUserCount] = useState(0);
    const [usernames, setUsernames] = useState([]);

    // Messages States
    const [message, setMessage] = useState("");
    //const [messageReceived, setMessageReceived] = useState("");
    const [messageReceived, setMessageReceived] = useState([]);

    const joinRoom = () => {
        if (room !== "") {
            socket.emit("join_room", room, username);
            inputEntry.current.focus();
        } else {
            alert('Please select a channel');
        }
    };

    const sendMessage = (e) => {
        e.preventDefault()
        if (message !== "" && username !== "") {
            const timestamp = new Date().toLocaleString();
            socket.emit("send_message", { message, room, username, timestamp });
            setMessage(""); // Clear the message input field
        }else{
            alert('No username and message set, configure both!')
        }
    };

    useEffect(() => {
        socket.on("all_messages", (messages) => {
            setMessageReceived(messages);
        });

        socket.on("receive_message", (data) => {
            setMessageReceived(prevMessages => [...prevMessages, data]);
        });

        socket.on("usernames", (data) => {
            setUsernames(data);
        });

        socket.on("user_count", (data) => {
            setUserCount(data);
        });



        /*
        return () => {
            socket.off("all_messages");
            socket.off("receive_message");
        };
        */
    }, [socket, message]);

    useEffect(() => {
        const container = lastMesage.current;
        inputEntry.current.style.marginTop = "10px"
        if (container) {
            const bottomOffset = 10; // Adjust the offset value as needed
            const targetTop = container.offsetTop + container.offsetHeight - bottomOffset;
            container.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest", top: targetTop });
        }
    }, [messageReceived]);

    const options = { 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric' ,
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    };
    
    const staff = []
    
    return (
        <div>
            <Top />
            <Navigator />
            {/*<PageBanner page={'Our ChatRooms'}/>*/}
            <div class="main_heading text-center d-none">
                <h2 className='text-dark'>Join a ChatRoom To Contribute</h2>
                <p class="large">We are delighted to have you here</p>
            </div>
            <div className='d-block d-md-flex gap-4 chat mt-4'>
                <div className='inner'>
                    <h3>Create Chat Profile</h3>
                    <div className="flexed">
                        <div className='d-block'>
                            <label>Name</label>
                            <input type='text' onChange={(event) => {setUsername(event.target.value)}} className='flex-grow-1' placeholder='username'/>
                        </div>
                        <div className='d-block'>
                            <label>Select Channel</label>
                            <select className="w-100 rounded" onChange={(event) => {setRoom(event.target.value)}}>
                                <option>Select a chatRoom to Join</option>
                                <option value={'Channel 1'}>Channel 1</option>
                                <option value={'Channel 2'}>Channel 2</option>
                                <option value={'Channel 3'}>Channel 3</option>
                                <option value={'Channel 4'}>Channel 4</option>
                                <option value={'Channel 5'}>Channel 5</option>
                            </select>
                        </div>
                    </div>
                    <button onClick={joinRoom} className='btn btn-danger w-100 mb-4'>Join Room</button>
                    <div className="d-none d-md-block">
                        <h3>Online Users {userCount}</h3>
                        {usernames && usernames.length > 0 && usernames.map(item => 
                            <p className='border-bottom pb-2'>{item}</p>
                        )}
                        
                    </div>
                </div>
                <div className='d-flex flex-column justify-content-between flex-grow-1 pb-4 displayed'>
                    <div className='messages d-none'>
                        <p className='fw-bolder d-flex justify-content-end border rounded p-3'>Person Name</p>
                    </div>
                    <div className='messages msgDiv'>
                        {messageReceived.length > 0 && messageReceived.map((msg, index) => (
                            <p ref={lastMesage} key={index} className={`d-flex justify-content-${msg.username === username ? 'end' : 'start'}`}>
                                <p className={`p-3 rounded ${msg.username === username ? 'received text-white' : 'sent text-white'}`}>
                                    <p className="m-0 pb-2 fw-bolder ">{msg.username}</p>
                                    <p className="m-0 mb-2">{msg.message}</p>
                                    <small style={{textTransform: "lowercase"}}>{new Intl.DateTimeFormat('en-US', options).format(new Date(msg.timestamp))}</small>
                                </p>
                            </p>
                        ))}
                    </div>
                    <form onSubmit={sendMessage} className='messageInput d-flex gap-4 mt-3 align-items-center'>
                        <input type='text' className="mb-0 mt-0" ref={inputEntry} placeholder='send message .....' value={message} onChange={(event) => {setMessage(event.target.value)}} />
                        <button className='btn btn-danger flex-grow-1'>send</button>
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ChatRoom