import React, { useState, useEffect } from 'react'
import Footer from './Footer'
import Navigator from './Navigator'
import Top from './Top'
import PageBanner from './PageBanner'
import News, {NewsSkeleton} from './News'
import useSmoothScrollToTop from './useSmoothScroll'
import { domain } from './Domain'
import useFetch from './useFetch'
import { motion, AnimatePresence } from 'framer-motion'
import "../css/Clubs.css"
import { Link } from 'react-router-dom'
import useApiRequest from './useApiRequest'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Loader from './Loader'
import { Helmet } from 'react-helmet';


export const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        //delayChildren: 0.2,
        //staggerDirection: 1,
        //duration: 2
      }
    }
}
  
export const item = {
    hidden: { opacity: 0, x: -100 },
    //show: { opacity: 1, x: 0, transition: {type: "spring", bounce: 0.25,  stiffness: 200}}
    show: { opacity: 1, x: 0}
}

const Welcome = () => {
    return(
        <>
            <motion.div 
                initial = {{x:-100, rotate: "2.5deg"}}
                animate = {{x: 0, rotate: "0deg"}}
                transition={{duration: 1, type: 'spring', stiffness: 200, damping: 10}}
                class="main_heading text-center">
                <h2 className='text-dark'>Introductory Information</h2>
                <p class="large">At our Best with Divine Clubs</p>
            </motion.div>
            <motion.div
                initial = {{y:100, rotate: "2.5deg"}}
                animate = {{y: 0, rotate: "0deg"}}
                transition={{duration: 1, type: 'spring', stiffness: 200, damping: 10}}
                className='newsDiv'>
                <h1 className='fw-bold'>Welcome to Divine Listeners' Club!</h1>
                <p>Dive into the world of rhythm and melody with Divine Listeners' Club, the hub for all music enthusiasts at our radio station. Whether you're passionate about discovering new tunes or discussing the classics, this club offers a harmonious space for every listener to share their love for music. Join us as we explore diverse genres, host engaging discussions, and celebrate the power of music to unite us all.</p>
                <h1 className='fw-bold'>Experience the Divine Cultural Club!</h1>
                <p>Step into a realm of cultural diversity and exploration with the Divine Cultural Club. Here, we embrace the rich tapestry of traditions, languages, and customs from around the globe. Through vibrant events, enlightening discussions, and immersive experiences, our club invites you to broaden your horizons and deepen your appreciation for the world's diverse cultures. Join us as we celebrate unity in diversity and embark on a journey of cultural discovery together.</p>
                <h1 className='fw-bold'>Kick-off with the Divine Football Club!</h1>
                <p>Are you ready to feel the pulse-pounding excitement of the beautiful game? Join the Divine Football Club and immerse yourself in the thrilling world of soccer. Whether you're a die-hard fan or a casual observer, our club offers a lively space to discuss matches, share insights, and cheer on your favorite teams. From heated debates to friendly predictions, join us as we unite through our shared passion for football and celebrate the universal language of the sport.</p>
                <h1 className='fw-bold'>Stride with the Divine Athletics Club!</h1>
                <p>Fuel your passion for fitness and athleticism with the Divine Athletics Club. Whether you're a seasoned athlete or a newcomer to the world of sports, our club provides a supportive environment to pursue your fitness goals and connect with fellow enthusiasts. Join us for exhilarating workouts, inspiring guest speakers, and exciting challenges as we strive for personal excellence and celebrate the joy of movement together. Lace up your sneakers and join us on the path to a healthier, happier you!</p>
            </motion.div>
        </>

    )
}

const Club = ( {setActive} ) => {
    const handleClick = (e) => {
        setActive(e)
    }
    return (
        <motion.div
            variants={container}
            initial="hidden"
            animate="show"
            className='clubs'
        >
            <div className='d-flex flex-wrap gap-4 justify-content-center align-items-center'>
                <motion.p whileHover={{scale: 1.01}} whileTap={{scale: 0.95, background: "grey"}} onClick={()=> handleClick(11)} variants = {item}>Divine Listeners'Club</motion.p>
                <motion.p whileHover={{scale: 1.01}} whileTap={{scale: 0.95, background: "grey"}} onClick={()=> handleClick(12)} variants = {item}>Divine Cultural Club.</motion.p>
                <motion.p whileHover={{scale: 1.01}} whileTap={{scale: 0.95, background: "grey"}} onClick={()=> handleClick(13)} variants = {item}>Divine Football Club.</motion.p>
                <motion.p whileHover={{scale: 1.01}} whileTap={{scale: 0.95, background: "grey"}} onClick={()=> handleClick(14)} variants = {item}>Divine Athletics Club.</motion.p>
            </div>
        </motion.div>
    )
}

const Clubs = () => {
    const [active, setActive] = useState(null)
    const [description, setDescription] = useState(null)
    const [heading, setHeading] = useState(null)
    const [images, setImages] = useState(null)
    const { getRequest } = useApiRequest()
    const [selectedId, setSelectedId] = useState(null)
    const [selectedImage, setSelectedImage] = useState(null)
    const [loadingImages, setLoadingImages] = useState([]);
    const [ spinner, setSpinner ] = useState(true);
    useEffect(()=>{
        setTimeout(() => setSpinner(false), 500);
    },[])
    const handleClick = async (e, id, data, itemId) => {
        setActive(id)
        setDescription(data)
        setHeading(e.target.textContent)
        const images = await getRequest(`${domain}/gallery?id=${itemId}`)
        if(images.length > 0){
            images?.map((img)=>{
                setLoadingImages((prevLoadingImages) => [...prevLoadingImages, img.id]);
            })
        }
        setImages(images)
    }
    const handleImageClick = (id, image) => {
        setSelectedId(id)
        setSelectedImage(image)

    }
    const {data:clubData, loading:loadingClubs, error:errClubs, refresh:refClubs, setRefresh:setRefClubs} = useFetch(`${domain}/clubs`);
    const items = Array(5).fill(null);
    useSmoothScrollToTop();

    const handleImageLoad = (id) => {
        setLoadingImages((prevLoadingImages) => prevLoadingImages.filter((imageId) => imageId !== id));
        console.log("this is the new data")
        console.log(loadingImages)
    };
    return (
        spinner ? <Loader /> :
        <div>
            <Helmet>
                <title>Our Clubs</title>
                <meta name="description" content="Dive into the world of rhythm and melody with Divine Listeners' Club, the hub for all music enthusiasts at our radio station. Whether you're passionate about discovering new tunes or discussing the classics, this club offers a harmonious space for every listener to share their love for music. Join us as we explore diverse genres, host engaging discussions, and celebrate the power of music to unite us all." />
                <meta property="og:title" content="Our Clubs" />
                <meta property="og:description" content="Dive into the world of rhythm and melody with Divine Listeners' Club, the hub for all music enthusiasts at our radio station. Whether you're passionate about discovering new tunes or discussing the classics, this club offers a harmonious space for every listener to share their love for music. Join us as we explore diverse genres, host engaging discussions, and celebrate the power of music to unite us all." />
                <meta property="og:image" content="https://radiodivine.co.ug/images/divine_logo.png" />
                <meta property="og:url" content="https://radiodivine.co.ug/images/divine_logo.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Our Clubs" />
                <meta name="twitter:description" content="Dive into the world of rhythm and melody with Divine Listeners' Club, the hub for all music enthusiasts at our radio station. Whether you're passionate about discovering new tunes or discussing the classics, this club offers a harmonious space for every listener to share their love for music. Join us as we explore diverse genres, host engaging discussions, and celebrate the power of music to unite us all." />
                <meta name="twitter:image" content="https://radiodivine.co.ug/images/divine_logo.png" />
            </Helmet>
            <Top />
            <Navigator />
            <PageBanner page={'Clubs Available'}/>
                <div className="side-panel">
                    {loadingClubs ? 
                    <div className='d-flex gap-2'>
                        {items.map(item => <Skeleton width={150} height={60} key={item} />)}
                    </div>
                    :
                    <>
                        <motion.span
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.9 }}>
                            <Link onClick={(e)=>handleClick(e, null, null)}>Home / Start screen</Link>
                        </motion.span>
                        {clubData && clubData.map((item, index) => (
                            <motion.span
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.9 }}
                                key={index}>
                                    <Link onClick={(e)=>handleClick(e, index, item.description, item.id)}>{item.name}</Link>
                                </motion.span>
                            
                        ))}
                    </>
                    }
                </div>
                <div className="scrollable-content">
                    {active === null ? 
                        <Welcome />
                        :
                        <>
                            <motion.h2 className='h2' initial="hidden" animate="show" variants = {item}>{heading}</motion.h2>
                            <motion.p initial="hidden" animate="show" variants = {item}>{description}</motion.p>
                            <motion.div className='d-block d-md-flex gap-2 flex-wrap'>
                                {images && images.length > 0 && images.map((item) => (
                                    <div className='image flex-grow-1 bg-light '>
                                        {loadingImages.includes(item.id) && <Skeleton width={"100%"} height={250} />}
                                        <motion.img
                                            initial={{opacity: 0, scale: 0.2}} 
                                            animate={{opacity:1, scale:1}} 
                                            key={item.id} 
                                            src={`${domain+item.image}`} 
                                            layoutId={item.id} 
                                            onClick={() => handleImageClick(item.id, domain+item.image)} 
                                            //loading='lazy'
                                            onLoad={() => handleImageLoad(item.id)} // Add onload event handler
                                            style={{ display: loadingImages.includes(item.id) ? 'none' : 'block' }}/>
                                    </div>
                                ))}
                            </motion.div>
                        </>
                    }
                </div>
                <AnimatePresence>
                    {selectedId && (
                        <div className='popupImage'>
                            <motion.img initial={{opacity: 0, scale: 0}} animate={{opacity:1, scale:1}} key={item.id} src={selectedImage} layoutId={selectedId} onClick={() => {setSelectedId(null); setSelectedImage(null)}} loading='lazy'/>
                        </div>
                        )
                    }
                </AnimatePresence>
            <Footer />
        </div>
  )
}

export default Clubs