import React, {useEffect, useState} from 'react'
import Footer from './Footer'
import Navigator from './Navigator'
import Top from './Top'
import PageBanner from './PageBanner'
import News, {NewsSkeleton} from './News'
import useSmoothScrollToTop from './useSmoothScroll'
import { domain } from './Domain'
import useFetch from './useFetch'
import { motion } from 'framer-motion'
import Loader from './Loader'
import { Helmet } from 'react-helmet'

const NewsPage = () => {

    const [ spinner, setSpinner ] = useState(true);
    useEffect(()=>{
        setTimeout(() => setSpinner(false), 500);
    },[])

    const {data:news, loading:loadingNews, error:errNews, refresh:refNews, setRefresh:setRefNews} = useFetch(`${domain}/news`);
    const items = Array(3).fill(null);
    const data = {
        id: 1,
        cover: "https://api.cynaut-tech.com/media/news/1_IMG-20240202-WA0021.jpg",
        title: "The Head of Department and staff begin the second semester 2024 in style",
        by: "Eng. David",
        details: "The Department of Geography, Geo-Informatics, and Climatic Sciences at kicked off the second semester of 2024 with a celebratory spirit, as the Head of the Department, Dr. Paul Mukwaya, extended which was"
    }
    useSmoothScrollToTop();
    return (
        spinner ? <Loader /> :
        <div>
            <Helmet>
                <title>News</title>
                <meta name="description" content="We bring to you official and trending news hightlights around the globe" />
                <meta property="og:title" content="News" />
                <meta property="og:description" content="We bring to you official and trending news hightlights around the globe" />
                <meta property="og:image" content="https://radiodivine.co.ug/images/divine_logo.png" />
                <meta property="og:url" content="https://radiodivine.co.ug/images/divine_logo.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="News" />
                <meta name="twitter:description" content="We bring to you official and trending news hightlights around the globe" />
                <meta name="twitter:image" content="https://radiodivine.co.ug/images/divine_logo.png" />
            </Helmet>
            <Top />
            <Navigator />
            <PageBanner page={'News Page'}/>
            <motion.div 
                initial = {{x:-100, rotate: "2.5deg"}}
                animate = {{x: 0, rotate: "0deg"}}
                transition={{duration: 1, type: 'spring', stiffness: 200, damping: 10}}
                class="main_heading text-center">
                <h2 className='text-dark'>STAY UPDATED 24/7</h2>
                <p class="large">We bring to you official and trending news hightlights around the globe</p>
            </motion.div>
            <div className='newsDiv'>
                <motion.h1 initial = {{opacity: 0}} animate = {{opacity: 1}} className='mb-3 ps-3'>News & Events</motion.h1>
                {loadingNews ?  items.map((item, index) => (<NewsSkeleton key={index}/>)) :
                    <>
                        {news && 
                        (
                            news.length > 0 && news.map((data, index) => {
                            return(
                                <div className='flex'>
                                    <News data={data} key={data.id} index = {index}/>
                                </div>
                            )
                            })
                        )
                        }
                    </>
                }
            </div>
            <Footer />
        </div>
  )
}

export default NewsPage