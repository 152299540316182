import React, {useState} from 'react'
import "../css/Programs.css"
import { Link, json } from 'react-router-dom'
import { domain } from './Domain'
import useFetch from './useFetch'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { motion } from 'framer-motion'

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


export const ProgramSkeleton = () => {

    return(
        <div className='news_skeleton'>
            <SkeletonTheme baseColor="#ddd3" highlightColor="#5555">
                <div>
                    <Skeleton height={200} width={"100%"} count={1} />
                </div>
            </SkeletonTheme>
        </div>
    )
}

export const Program = ({data, days, activeDay, index}) => {
    const [show, setShow] = useState(false);
    const {data:schedule, loading:loadingSchedule, error:errSchedule, refresh:refSchedule, setRefresh:setRefSchedule} = useFetch(`${domain}/schedules?program=${data.id}`);
    

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
    };
    return(
        <motion.div initial={{opacity: 0, scale: 0}} viewport={{ once: true }}  whileInView={{opacity: 1, scale: 1}} transition={{ duration: 0.5, delay: index * 0.1 }} className='inner' style={{background: `url(${domain+data.cover})`}}>
            <div>
                <h2>{data.title}</h2>
                <div className='d-flex justify-content-between'>
                    <h3>{data.time}</h3>
                    <h4>{days[activeDay]}</h4>
                </div>
            </div>
            <div className='positioned'>
                <div className='d-flex bg justify-content-between'>
                    <h3>{data.time}</h3>
                    <h4>{days[activeDay]}</h4>
                </div> 
                <div>
                    <p>Entertainment News, Relationship Counseling at love at 11, Movie and cinema reviews.[...]</p>
                    <Link className='fw-bold text-danger' onClick={handleShow}>Learn more</Link>
                </div>
            </div>
            <Modal show={show} size="lg" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className='fw-bold'>{data.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4 className='mb-3 mt-0 text-secondary'>Presenter</h4>
                    <h3 className='fs-6 pb-2'>{data.presenter}</h3>
                    <table className='programTable'>
                        <thead>
                            <tr>
                                <th>Time</th>
                                <th>program</th>
                            </tr>
                        </thead>
                        <tbody>
                        {schedule && schedule.map((item, index)=>(
                            <tr key={index}>
                                <td>{item.time_from} - {item.time_to}</td>
                                <td>{item.title}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer className='d-none'>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </motion.div>
    )
}

const Programs = () => {
    const items = Array(6).fill(null);
    const [activeDay, setActiveDay] = useState(0);
    const days = ['weekdays', 'Saturday', 'Sunday'];
    const {data:programs, loading:loadingPrograms, error:errPrograms, refresh:refPrograms, setRefresh:setRefPrograms} = useFetch(`${domain}/programs?category=${days[activeDay]}`);

    const handleClick = (index) => {
        setActiveDay(index);
    };

    return (
        <div className='programs bg-body-tertiary'>
            <motion.div initial={{opacity: 0, x: -100}} whileInView={{opacity: 1, x: 0}} viewport={{ once: true }}  transition={{duration: 1}} className='text-center progDiv'>
                <h4 className='fw-bolder text-danger'>Our Radio Programs</h4>
                <p className='w-100 w-md-75 m-auto pt-4 pb-0'>Step into the vibrant world of radio entertainment with Radio Divine, where every tune, every word, and every moment is crafted to captivate and delight our listeners. As you tune in to [mention your frequency or channel], get ready to embark on a journey filled with a diverse array of programs that cater to every taste and preference.</p>
                <p className='w-100 w-md-75 m-auto pt-4 pb-0'>Step into the vibrant world of radio entertainment with Radio Divine, where every tune, every word, and every moment is crafted to captivate and delight our listeners. As you tune in to [mention your frequency or channel], get ready to embark on a journey filled with a diverse array of programs that cater to every taste and preference.</p>
                <p className='w-100 w-md-75 m-auto pt-4 pb-4'>From the crack of dawn till the midnight hour, our lineup of captivating programs promises to keep you entertained, informed, and connected to the pulse of our community. Whether you're a music aficionado looking to groove to the latest hits or a news enthusiast seeking insightful discussions and updates, we've got something for everyone.</p>
            </motion.div>
            <div className='d-flex gap-2 justify-content-center'>
                {days.map((day, index) => (
                    <button 
                        className={`btn ${index === activeDay ? 'btn-active' : ''}`}
                        key={index}
                        onClick={() => handleClick(index)}>{day}</button>
                ))}
            </div>
            <div className='d-flex gap-4 programList flex-wrap'>
                {loadingPrograms ?  items.map((item, index) => (<ProgramSkeleton key={index}/>)) 
                :
                programs && programs.map((item, index)=>(
                    <Program key={index} data = {item} days = {days} activeDay = {activeDay} index = {index}/>
                ))}
            </div>
        </div>
    )
}

export default Programs