import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import "../css/OnlineStream.css"
import { useActiveStream } from './StreamPlayerContext'

const OnlineStream = () => {
    const {opened, setOpened}= useActiveStream()

    const openStream = () => {
        setOpened(()=>!opened)
    }
    return (
        <>
            <Link onClick={openStream} className='online' title='listen to our online stream'><i class="fa-solid fa-tower-cell"></i></Link>
            <div className="onlineStream" style={{display: opened === false ? 'none' : 'block'}}>
                <h3 className='fs-6 fw-bold pt-3 pb-2 ps-3 text-success'>Click the button to start listening</h3>
                <iframe src="https://streams.radiomast.io/station/52190067-8a8a-45dd-9c8d-712e2499ca00/player/widget?t=dark&s=e834750f-ace2-41c1-9922-34d182933a6d" width="100%" height="90%" scrolling="no" frameborder="0" allow="autoplay" ></iframe>
            </div>
        </>
    )
}

export default OnlineStream