import React, {useState, useEffect, useRef} from 'react'
import { Link } from 'react-router-dom'
import { domain } from './Domain'
import "../css/News.css"

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { motion } from "framer-motion";


export const NewsSkeleton = () => {

    return(
        <div className='news_skeleton'>
            <SkeletonTheme baseColor="#ddd3" highlightColor="#5555">
                <div>
                    <Skeleton height={300} width={"100%"} count={1} />
                    <div>
                        <Skeleton height={20} width={"30%"} count={1} />
                        <Skeleton height={10} count={1} /><Skeleton height={10} width={"20%"} count={1} />
                        <Skeleton height={10} width={"10%"} count={1} /><Skeleton height={10} width={"30%"} count={1} />
                        <Skeleton height={10} count={4} />
                        
                        <Skeleton height={10} width={"25%"} count={1} />
                    </div>
                </div>
            </SkeletonTheme>
        </div>
    )
}
const News = ({data, index}) => {

  const truncateString = (str, len)=> {
    return str.length > len ? str.substring(0, len) + "..." : str;
  }

  const [loaded, setLoaded] = useState(false);

  const handleImageLoad = () => {
      setLoaded(true);
  };
  const options = { 
    year: 'numeric', 
    month: 'long', 
    day: 'numeric' 
  };


  return (
    <motion.div
        initial={{opacity: 0, scale: 0,  y: -64}}
        whileInView={{opacity: 1, scale: 1, y: 0}}
        //animate={{ opacity: 1, scale: 1, y: 0 }}
        exit={{ opacity: 0, y: -50 }}
        transition={{ duration: 0.5, delay: index * 0.1 }}
        viewport={{ amount: 0.1, once: true }} 
        className='news'>
            {!loaded && 
              <div className='w-100 bg-secondary' style={{height: "250px"}}>
              </div>
            }
            <img 
              src={domain+data.cover}
              onLoad={handleImageLoad}
              style={{ display: loaded ? 'block' : 'none' }} />
            <div className='bg-light'>
                <span>{new Intl.DateTimeFormat('en-US', options).format(new Date(data.created_at))}</span>
                <h3>{truncateString(data.title, 40)}</h3>
                <span>by <b>{data.by}</b></span>
                <p className='pt-2' dangerouslySetInnerHTML={{ __html: truncateString(data.details, 199) }}></p>
                <Link to={`/news/${data.slug}`} className='text-danger fw-bolder'>More Informtion</Link>
            </div>
    </motion.div>
  )
}

export default News