import React, {useState, useEffect} from 'react'
import Footer from './Footer'
import Navigator from './Navigator'
import Top from './Top'
import PageBanner from './PageBanner'
import useSmoothScrollToTop from './useSmoothScroll'
import "../css/About.css"
import { motion } from 'framer-motion'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Loader from './Loader'
import { Helmet } from 'react-helmet';

const About = () => {
    useSmoothScrollToTop();
    const [loaded, setLoaded] = useState(false);

    const handleImageLoad = () => {
        setLoaded(true);
    };

    const [ spinner, setSpinner ] = useState(true);
    useEffect(()=>{
        setTimeout(() => setSpinner(false), 500);
    },[])
    return (
        spinner ? <Loader /> : 
        <div>
            <Helmet>
                <title>About Us</title>
                <meta name="description" content="Radio Divine (90.6 Fm) is a nonsectarian, nonpartisan, development oriented Radio station based in Akere Division, central Ward, Apac Municipal council; blessed with super qualified personnel who are self started, flexible and result oriented; it exists to scatter to a larger part of the Ugandan communities, resourceful information from programs tailored to glorify God, educate, inform and entertain people of all age groups irrespective of their social, political, and/or economic divergence." />
                <meta property="og:title" content="Get to know who we really are" />
                <meta property="og:description" content="Radio Divine (90.6 Fm) is a nonsectarian, nonpartisan, development oriented Radio station based in Akere Division, central Ward, Apac Municipal council; blessed with super qualified personnel who are self started, flexible and result oriented; it exists to scatter to a larger part of the Ugandan communities, resourceful information from programs tailored to glorify God, educate, inform and entertain people of all age groups irrespective of their social, political, and/or economic divergence." />
                <meta property="og:image" content="https://radiodivine.co.ug/images/divine_logo.png" />
                <meta property="og:url" content="https://radiodivine.co.ug/images/divine_logo.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Get to know who we really are" />
                <meta name="twitter:description" content="Radio Divine (90.6 Fm) is a nonsectarian, nonpartisan, development oriented Radio station based in Akere Division, central Ward, Apac Municipal council; blessed with super qualified personnel who are self started, flexible and result oriented; it exists to scatter to a larger part of the Ugandan communities, resourceful information from programs tailored to glorify God, educate, inform and entertain people of all age groups irrespective of their social, political, and/or economic divergence." />
                <meta name="twitter:image" content="https://radiodivine.co.ug/images/divine_logo.png" />
            </Helmet>
            <Top />
            <Navigator />
            <PageBanner page={'Get To Know Us'}/>
            <motion.div 
                initial = {{x:-100, rotate: "2.5deg"}}
                animate = {{x: 0, rotate: "0deg"}}
                transition={{duration: 1, type: 'spring', stiffness: 200, damping: 10}}
                class="main_heading text-center">
                <h2 className='text-dark'>WE ARE A LEADING RADIO STATION</h2>
                <p class="large">Always ready to serve your needs!</p>
            </motion.div>
            <div class="row about_blog">
                <motion.div initial={{opacity: 0, y: 100}} viewport={{ once: true }} whileInView={{opacity: 1, y: 0}} transition={{duration: 1}} class="col-lg-12 col-md-8 col-sm-12 about_cont_blog pb-2">
                    <div class="text_align_left">
                        <h3>Who we are</h3>
                        <p>Radio Divine (90.6 Fm) is a nonsectarian, nonpartisan, development oriented Radio station based in Akere Division, central Ward, Apac Municipal council; blessed with super qualified personnel who are self started, flexible and result oriented; it exists to scatter to a larger part of the Ugandan communities, resourceful information from programs tailored to glorify God, educate, inform and entertain people of all age groups irrespective of their social, political, and/or economic divergence.</p>
                        <p>It provides facts about health, education and development. Its programming is designed for the integral development of the listeners: spiritually, socially, intellectually, and physically.</p>
                        <p>Radio Divine has patent objective programs which are broader in scope to provide link between a cross section of people in Uganda, and has generated a feeling that we are all part of something larger than ourselves. Radio Divine (90.6 Fm) strives to promote orientation towards economic empowerment, responsibilities and continued participation towards local and global development, through its unrivalled reputation gained from its far-reaching, well researched community-based programs other than it stretched coverage.</p>
                    </div>
                </motion.div>
                <motion.div initial={{opacity: 0, y: 100}} viewport={{ once: true }} whileInView={{opacity: 1, y: 0}}  transition={{duration: 1}} class="col-lg-12 col-md-8 col-sm-12 about_cont_blog pb-2 pt-2">
                    <h3>Radio Divine Goal</h3>
                    <p>The goal of Radio Divine is to promote and mobilize the communities to proactively participate in development processes together with other similar-minded organizations and stakeholders such as the Government, None Governmental Organizations (NGOs), CBOs, and development partners both locally and internationally; in order to create a socially inclusive environment where all young people are engaged, informed and inspired to take on the challenges in their development processes as the route to achieving their ambitions and dreams.</p>
                </motion.div>
                <motion.div initial={{opacity: 0, y: 100}} viewport={{ once: true }} whileInView={{opacity: 1, y: 0}}  transition={{duration: 2}} class="col-lg-7 col-md-8 col-sm-12 about_cont_blog pt-2 pb-2">
                    <h3>Our Values</h3>
                    <ul style={{listStyle: 'none'}}>
                        <li className='d-flex'><i class="fa fa-check-circle"></i><span>Radio Divine educates informs and entertains people of different backgrounds while promoting the wellbeing of our community</span></li>
                        <li className='d-flex'><i class="fa fa-check-circle"></i><span>Radio Divine provides distinct means of education for listeners through well researched topics covering health, women’s rights, domestic and family life, agriculture, community development, schools, and business development among others; these topics reach out via programmes   such as Spot ads, Dramas, Dj Mentions, Talk shows, Features, and News.</span></li>
                        <li className='d-flex'><i class="fa fa-check-circle"></i><span>Radio Divine news coverage has rich reputation for its complete National and World-wide news coverage.</span></li>
                    </ul>
                    <h3>Objectives of sponsorship</h3>
                    <ul style={{listStyle: 'none'}}>
                        <li className='d-flex'><i class="fa fa-check-circle"></i><span>To develop a comprehensive advocacy and a framework to guide other actors and/or community on sustainable development processes.</span></li>
                        <li className='d-flex'><i class="fa fa-check-circle"></i><span>To enhance Radio Divines’ efficiency and the effectiveness of its operation strategies</span></li>
                        <li className='d-flex'><i class="fa fa-check-circle"></i><span>To involve as one of the development oriented players, in Radio programs as part of its aspiration to create opportunities for all.</span></li>
                        <p>At the end of the sponsorship, listeners/communities will:</p>
                        <li className='d-flex'><i class="fa fa-check-circle"></i><span>Gain deeper understanding of the necessity of their participation in development programs in different localities.</span></li>
                        <li className='d-flex'><i class="fa fa-check-circle"></i><span>Achieve in-depth knowledge about public health, human rights, domestic affairs, agriculture, family life, and entrepreneurship among others.</span></li>
                    </ul>
                </motion.div>
                <motion.div initial={{opacity: 0, y: 100}} viewport={{ once: true }} whileInView={{opacity: 1, y: 0}}  transition={{duration: 2}} class="col-lg-5 col-md-8 col-sm-12">
                    {!loaded && 
                        <Skeleton height={400} width={"100%"} count={1} />
                    }
                    <img 
                        class="img-fluid" 
                        src="images/coverage.png" 
                        onLoad={handleImageLoad}
                        style={{ display: loaded ? 'block' : 'none' }}
                        alt="#" /> 
                </motion.div>
                <motion.div initial={{opacity: 0, y: 100}} viewport={{ once: true }} whileInView={{opacity: 1, y: 0}}  transition={{duration: 1}} class="col-lg-12 col-md-8 col-sm-12 about_cont_blog pt-2 pb-2">
                    <h3>Our Signal</h3>
                    <p>Radio Divine 90.6 Fm signal has a radium of about 250 Km. with about 7.2 million people (listeners), With excellent coverage in Apac, Lira, Oyam, Kole, Gulu, Pader, Nwoya, Agago, Otuke, Alebtong, Dokolo, Amolatar, Kiryandongo, Nakasongola, Masindi Districts. We also reach out with very good signal, Nebbi, Amuru, Adjumani, Lamwo, Kitgum, Kotido, Abim, Napak, Amuru, Amuru, Katakwi, Soroti, Ngora, Serere, Kayunga, Luwero, Nakaseke, Nakasongola, Hoima, and Bulisa Districts.</p>
                    <h3>Languages of Broadcast</h3>
                    <p>Radio Divine broadcasts in different languages in order to reach out to all the communities in Luo (Lango, Acholi, Alur, and Kumam), English, Kiswahili, Luganda, and Ateso.</p>
                </motion.div>
            </div>
            <Footer />
        </div>
    )
}

export default About