import React, { useState, useEffect, useRef } from 'react';
import useApiRequest from './useApiRequest';
import { domain } from './Domain';
import "../css/CategoryComponent.css";

const NewsComponent = () => {
  const { postRequest, getRequest, putRequest, deleteRequest } = useApiRequest();
  const inputRef = useRef();
  const [newsList, setNewsList] = useState([]);
  const [selectedNewsId, setSelectedNewsId] = useState(null);
  const initialFormState = {
    by: '',
    category: 'Others',
    title: '',
    cover: null,
    details: '',
  };
  const [form, setForm] = useState(initialFormState);

  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = async () => {
    const response = await getRequest(`${domain}/news`);
    setNewsList(response);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleFileChange = (e) => {
    setForm({ ...form, cover: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    for (const key in form) {
      if (key === "cover") {
        if (form[key] !== null) {
          formData.append(key, form[key]);
        }
      } else {
        formData.append(key, form[key]);
      }
    }
    if (selectedNewsId) {
      await putRequest(`${domain}/news/${selectedNewsId}`, formData);
    } else {
      await postRequest(`${domain}/news`, formData);
    }
    setForm(initialFormState);
    setSelectedNewsId(null);
    fetchNews();
  };

  const handleUpdate = (newsItem) => {
    setForm({
      by: newsItem.by,
      category: newsItem.category,
      title: newsItem.title,
      cover: null, // Reset the file input since we cannot prepopulate file input
      details: newsItem.details,
    });
    setSelectedNewsId(newsItem.id);
    inputRef.current.focus();
  };

  const handleCancelEdit = () => {
    setSelectedNewsId(null);
    setForm(initialFormState);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      await deleteRequest(`${domain}/news/${id}`);
      fetchNews();
    }
  };

  return (
    <div className='newsComponent'>
      <h5>News</h5>
      <form onSubmit={handleSubmit}>
        <div className='label d-flex gap-4'>
          <label>By:</label>
          <input type="text" ref={inputRef} name="by" placeholder='Author' value={form.by} onChange={handleInputChange} />
        </div>
        <div className='label d-flex gap-4'>
            <label>Category:</label>
            <select name="category" value={form.category} onChange={handleInputChange}>
              <option value="Business">Business</option>
              <option value="Health">Health</option>
              <option value="Sports">Sports</option>
              <option value="Lifestyle">Lifestyle</option>
              <option value="Others">Others</option>
            </select>
          </div>
        <div className='label d-flex gap-4'>
          <label>Title:</label>
          <input type="text" name="title" placeholder='Title' value={form.title} onChange={handleInputChange} />
        </div>
        <div className='label d-flex gap-4'>
          <label>Cover:</label>
          <input type="file" name="cover" className='border-0' onChange={handleFileChange} />
        </div>
        <div className='label d-flex gap-4'>
          <label>Details:</label>
          <textarea name="details" placeholder='Details' value={form.details} onChange={handleInputChange}></textarea>
        </div>
        <button type="submit" className='btn btn-sm btn-success'>{selectedNewsId ? 'Update' : 'Create News'}</button>
        {selectedNewsId && (
          <button type='button' onClick={handleCancelEdit} className='btn btn-sm btn-danger ms-2'>Cancel Update</button>
        )}
      </form>

      <h5 className='mt-3'>Existing News</h5>
      <ul className='p-0 m-0'>
        {newsList.map(newsItem => (
          <li key={newsItem.id} className='d-flex justify-content-between align-items-center border-bottom p-2 mb-2 rounded'>
            <div>
              <h2 className='fs-6 fw-bold'>{newsItem.title}</h2>
              <p>By: {newsItem.by}</p>
              <p dangerouslySetInnerHTML={{__html: newsItem.details}}></p>
              <img src={domain + newsItem.cover} style={{ width: 100 }} alt={newsItem.title} />
            </div>
            <div className='d-flex gap-2'>
              <button onClick={() => handleUpdate(newsItem)} className='btn btn-sm btn-danger'>Edit</button>
              <button onClick={() => handleDelete(newsItem.id)} className='btn btn-sm btn-success'>Delete</button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NewsComponent;
