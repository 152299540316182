// ActivePageContext.js
import React, { createContext, useContext, useState } from 'react';

const StreamActiveContext = createContext();

export const useActiveStream = () => {
  const context = useContext(StreamActiveContext);
  if (!context) {
    throw new Error('useActivePage must be used within an ActivePageProvider');
  }
  return context;
};

export const ActiveStreamProvider = ({ children }) => {
    const [opened, setOpened] = useState(false)

    const value = {
        opened,
        setOpened,
    };

  return <StreamActiveContext.Provider value={value}>{children}</StreamActiveContext.Provider>;
};
