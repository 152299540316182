import React, { useState, useEffect, useRef } from 'react';
import useApiRequest from './useApiRequest';
import useFetch from './useFetch';
import { domain } from './Domain';
import "../css/CategoryComponent.css"

const ProgramComponent = () => {
    const { postRequest, getRequest, putRequest, deleteRequest } = useApiRequest();
    const inputRef = useRef()
    const [programs, setPrograms] = useState([]);
    const [selectedProgramId, setSelectedProgramId] = useState(null);
    const initial = {
      day_of_week: 'Weekdays',
      time: '',
      title: '',
      presenter: '',
      description: '',
      cover: null,
    }
    const [form, setForm] = useState(initial);
  
    useEffect(() => {
      fetchPrograms();
    }, []);
  
    const fetchPrograms = async () => {
      const response = await  getRequest(`${domain}/programs`);
      setPrograms(response);
    };
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setForm({ ...form, [name]: value });
    };
  
    const handleFileChange = (e) => {
      setForm({ ...form, cover: e.target.files[0] });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      const formData = new FormData();
      for (const key in form) {
        if (key === "cover" && form[key] !== null) {
          formData.append(key, form[key]);
        } else if (key !== "cover") {
          formData.append(key, form[key]);
        }
      }
      if (selectedProgramId) {
        await putRequest(`${domain}/programs/${selectedProgramId}`, formData);
      } else {
        await postRequest(`${domain}/programs`, formData);
      }
      setForm(initial)
      fetchPrograms();
    };
  
    const handleUpdate = async (program) => {
      setForm({
        day_of_week: program.day_of_week,
        time: program.time,
        title: program.title,
        presenter: program.presenter,
        description: program.description,
        cover: null, // Reset the file input since we cannot prepopulate file input
      });
      setSelectedProgramId(program.id);
      inputRef.current.focus()
    };

    const handleCancleEdit = () => {
      setSelectedProgramId(null);
      setForm(initial)
    };
  
    const handleDelete = async (id) => {
      if(window.confirm("are your sure you want to delete this item")){
          const response = await deleteRequest(`${domain}/programs/${id}`);
          fetchPrograms();
      }
    };
  
    return (
      <div className='productComponent'>
        <h5>Programs</h5>
        <form onSubmit={handleSubmit}>
          <div className='label d-flex gap-4'>
            <label>Day of Week:</label>
            <select name="day_of_week" value={form.day_of_week} onChange={handleInputChange}>
              <option value="Weekdays">Weekdays</option>
              <option value="Saturday">Saturday</option>
              <option value="Sunday">Sunday</option>
            </select>
          </div>
          <div className='label d-flex gap-4'>
            <label>Time:</label>
            <input type="text" ref={inputRef} placeholder='time of program' name="time" value={form.time} onChange={handleInputChange} />
          </div>
          <div className='label d-flex gap-4'>
            <label>Title:</label>
            <input type="text" name="title" placeholder='program title' value={form.title} onChange={handleInputChange} />
          </div>
          <div className='label d-flex gap-4'>
            <label>Presenter:</label>
            <input type="text" name="presenter" placeholder='presenter' value={form.presenter} onChange={handleInputChange} />
          </div>
          <div className='label d-flex gap-4'>
            <label>Description:</label>
            <textarea name="description" placeholder='program description' value={form.description} onChange={handleInputChange}></textarea>
          </div>
          <div className='label d-flex gap-4'>
            <label>Cover:</label>
            <input type="file" name="cover" className='border-0' onChange={handleFileChange} />
          </div>
          <button type="submit" className='btn btn-sm btn-success'>{selectedProgramId ? 'Update' : 'Create Program'}</button>
          <button type='button' disabled={selectedProgramId ? false: true} onClick={handleCancleEdit} className='btn btn-sm btn-danger ms-2'>canel update</button>
        </form>
  
        <h5 className='mt-3'>Existing Programs</h5>
        <ul className='p-0 m-0'>
          {programs.map(program => (
            <li key={program.id} className='d-flex justify-content-between align-items-center border-bottom p-2 mb-2 rounded'>
              <div>
                <h2 className='fs-6 fw-bold'>{program.title}</h2>
                <p>{program.day_of_week}</p>
                <p>{program.time}</p>
                <p>{program.presenter}</p>
                <p>{program.description}</p>
                <img src={domain+program.cover} style={{width: 100}} alt={program.title} />
              </div>
              <div className='d-flex gap-2'>
                <button onClick={() => handleUpdate(program)} className='btn btn-sm btn-danger'>Update</button>
                <button onClick={() => handleDelete(program.id)} className='btn btn-sm btn-success'>Delete</button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  };

export default ProgramComponent