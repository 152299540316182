import React, {useState} from 'react'
import "../css/ContactForm.css"
const ContactForm = () => {
    const initialData = {
        name: "",
        email: "",
        tel: "",
        message: ""
    }
    const [data, setData] = useState(initialData)
    const handleChange = (event) => {
        const {name, value} = event.target;
        console.log(name, value)
        setData((prevData) => ({
            ...prevData,  // Spread the previous state
            [name]: value // Update the specific property with the new value
        }));
    }
    const handleSubmit = (event) => {
        event.preventDefault()
        const formData = new FormData()
        for (const key in data) {
            formData.append(key, data[key]);
        }
        const mailtoLink = `mailto:${'divinefm@gmail.com'}?subject=${encodeURIComponent(data.name)}&body=${encodeURIComponent(`${data.name} \n${data.tel} \n${data.email} \n${data.message}`)}`;
        window.location.href = mailtoLink;
    }
    return (
        <div className='contactForm'>
            <form onSubmit={handleSubmit}>
                <p className='fw-bolder'>Send us a message</p>
                <input type='text' name='name' value={data.name} onChange={handleChange} placeholder='Your Name' />
                <input type='email' name='email' value={data.email} onChange={handleChange} placeholder='Your Email Address' />
                <input type='tel' name='tel' value={data.tel} onChange={handleChange} placeholder='Your Phone Number' />
                <textarea name='message' onChange={handleChange} value={data.message} rows={4} placeholder='message'></textarea>
                <button className='btn btn-danger'>Submit Request</button>
            </form>
        </div>
    )
}

export default ContactForm